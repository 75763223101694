import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import SectionLayout from "../../components/sectionLayout";
import styles from "./styles.module.scss";
import amplitude from "amplitude-js";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Helmet } from "react-helmet";

interface question {
  description: string;
  isReverse: boolean;
}

const survey: question[] = [
  {
    description:
      "1. Når jeg fejler omkring noget som er vigtigt for mig, opsluges jeg af følelser af utilstrækkelighed.",
    isReverse: true,
  },
  {
    description:
      "2. Jeg prøver, at være forstående og tålmodig mod de dele af mig, som jeg ikke kan lide.",
    isReverse: false,
  },
  {
    description:
      "3. Når der sker noget svært eller smertefuldt, så forsøger jeg at skabe et nuanceret perspektiv på situationen.",
    isReverse: false,
  },
  {
    description:
      "4. Når jeg føler mig nedtrykt, så tænker jeg at de fleste andre nok er gladere end jeg er.",
    isReverse: true,
  },
  {
    description:
      "5. Jeg forsøger at se mine fejl, som en del af det at være menneske.",
    isReverse: false,
  },
  {
    description:
      "6. Når jeg går igennem svære perioder, så giver jeg mig selv den ømhed og omsorg jeg har behov for.",
    isReverse: false,
  },
  {
    description:
      "7. Når noget påvirker mig følelsesmæssigt, så forsøger jeg at bevare roen.",
    isReverse: false,
  },
  {
    description:
      "8. Når jeg laver fejl omkring noget som er vigtigt for mig, så føler jeg mig alene i mit nederlag.",
    isReverse: true,
  },
  {
    description:
      "9. Når jeg føler mig nedtrykt så fikserer jeg på alt det der er galt og forkert.",
    isReverse: true,
  },
  {
    description:
      "10. Når jeg føler mig utilstrækkeligt, så forsøger jeg at minde mig selv om at følelser af utilstrækkelighed er noget de fleste kender til.",
    isReverse: false,
  },
  {
    description:
      "11. Jeg er kritisk og fordømmende omkring mine egne fejl og utilstrækkeligheder.",
    isReverse: true,
  },
  {
    description:
      "12. Jeg er intolerant og utålmodig omkring de dele af mig selv som jeg ikke kan lide.",
    isReverse: true,
  },
];

const SurveySelvomsorg: React.FC = () => {
  const [answers, setAnswers] = useState<number[]>([survey.length]);
  const [currentAnswer, setCurrentAnswer] = useState<number | undefined>();
  const [surveyIndex, setSurveyIndex] = useState<number>(0);
  const [isIntroduction, setIsIntroduction] = useState<boolean>(true);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  useEffect(() => {
    amplitude.getInstance().logEvent("Show page: survey selvomsorg");
  }, []);

  const start = () => {
    setIsIntroduction(false);
  };

  const next = () => {
    if (surveyIndex < survey.length - 1) {
      setSurveyIndex(surveyIndex + 1);
      setCurrentAnswer(answers[surveyIndex + 1]);
    }
  };

  const previous = () => {
    if (surveyIndex === 0) {
      setIsIntroduction(true);
    }
    if (surveyIndex > 0) {
      setSurveyIndex(surveyIndex - 1);
      setCurrentAnswer(answers[surveyIndex - 1]);
    }
  };

  const complete = () => {
    setIsComplete(true);
    console.log("result", answers);
  };

  const retry = () => {
    setIsComplete(false);
    setSurveyIndex(0);
    setCurrentAnswer(undefined);
    setAnswers([survey.length]);
  };

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const answer = parseInt((event.target as HTMLInputElement).value);
    answers[surveyIndex] = answer;
    setAnswers(answers);
    setCurrentAnswer(answer);
  };

  const Introduction = () => (
    <div className={styles.introduction}>
      <h3>Test her hvor god du er til selvomsorg.</h3>
      <p>
        Denne test er frit oversat til dansk fra “Self-Compassion Scale Short
        Form” udviklet af Kristin Neff.
      </p>
      <p className={styles.reference}>
        Reference: Raes, F., Pommier, E., Neff, K. D., & Van Gucht, D. (2011).
        Construction and factorial validation of a short form of the
        Self-Compassion Scale. Clinical Psychology & Psychotherapy. 18, 250-255.
      </p>
    </div>
  );

  const Question = () => (
    <div className={styles.question}>
      <h3>{survey[surveyIndex].description}</h3>
      <div>
        <div className={styles.scaleLabels}>
          <div>
            Næsten
            <br />
            aldrig
          </div>
          <div>
            Næsten
            <br />
            altid
          </div>
        </div>
        <RadioGroup
          row
          name="position"
          defaultValue="top"
          className={styles.scaleValues}
          onChange={handleAnswerChange}
          value={currentAnswer}
        >
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="1"
            labelPlacement="top"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="2"
            labelPlacement="top"
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label="3"
            labelPlacement="top"
          />
          <FormControlLabel
            value="4"
            control={<Radio />}
            label="4"
            labelPlacement="top"
          />
          <FormControlLabel
            value="5"
            control={<Radio />}
            label="5"
            labelPlacement="top"
          />
        </RadioGroup>
      </div>
    </div>
  );
  const Result = () => (
    <div className={styles.result}>
      <h3>Din score er:</h3>
      <h2>{calculateScore()}</h2>
      <div className={styles.description}>
        <p>
          <b>0.0 - 2.5</b>: tegn på høj selvkritik og lav selvomsorg.{" "}
        </p>
        <p>
          <b>2.5 - 3.5</b>: middel selvkritik og middel selvomsorg.{" "}
        </p>
        <p>
          <b>3.5 - 5.0</b>: tegn på lav selvkritik og høj selvomsorg.
        </p>
        <p className={styles.disclaimer}>
          Resultaterne kan ikke bruges som andet end mulige indikationer for at
          du muligvis har høj eller lav selvomsorg. Testen er ment som et væktøj
          til at lære dig selv bedre at kende, og ikke som diagnoseværktøj.
        </p>
      </div>
    </div>
  );

  const calculateScore = (): string => {
    const sum = answers.reduce(
      (a, b, index) => a + (survey[index].isReverse ? 6 - b : b),
      0
    );
    return (sum / answers.length || 0).toFixed(1);
  };

  return (
    <Layout title="Selvomsorg testen">
      <Helmet>
        <meta
          name="description"
          content="Test dig selv. Test her hvor god du er til selvomsorg"
        />
        <meta name="keywords" content="test, psykolog, selvomsorg" />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://barselspsykologen.dk/test/selvomsorg"
        />
        <title>Selvomsorg testen | Barselspsykologen</title>
      </Helmet>
      <SectionLayout>
        <div className={styles.wrapper}>
          <div className={styles.survey}>
            {isComplete ? (
              <Result />
            ) : isIntroduction ? (
              <Introduction />
            ) : (
              <Question />
            )}
            <div className={styles.actions}>
              {isIntroduction && (
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={start}
                >
                  Tag testen
                </Button>
              )}
              {!isIntroduction && !isComplete && (
                <>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={previous}
                  >
                    Forrige
                  </Button>
                  <div className={styles.progress}>
                    {surveyIndex + 1 + "/" + survey.length}
                  </div>
                  {surveyIndex !== survey.length - 1 && (
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={next}
                      disabled={!currentAnswer}
                    >
                      Næste
                    </Button>
                  )}
                  {surveyIndex === survey.length - 1 && (
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={complete}
                      disabled={!currentAnswer}
                    >
                      Se resultat
                    </Button>
                  )}
                </>
              )}
              {!isIntroduction && isComplete && (
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={retry}
                >
                  Prøv igen
                </Button>
              )}
            </div>
          </div>
        </div>
      </SectionLayout>
    </Layout>
  );
};

export default SurveySelvomsorg;
