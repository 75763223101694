import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout";
import SectionLayout from "../../components/sectionLayout";
import "../../styles/content.scss";

const Cookies = () => (
  <Layout title="Cookies">
    <Helmet>
      <title>Cookies</title>
      <meta name="robots" content="nosnippet" />
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <SectionLayout>
      <h2>Cookies til statistik</h2>
      <p>
        Første gang du lander på denne hjemmeside, ser du et banner med
        information om cookies til statistik. Hvis du klikker på "Afvis", sættes
        der ingen cookies til statistik. Der bruges dog en cookie for at huske
        dit nej tak til statistik.
      </p>
      <p>
        Hvis du klikker "Accepter", sættes der cookies til at samle statistik,
        og banneret forsvinder. Statistikken bruges til at forbedre
        brugervenligheden. Oplysningerne i statistikken er anonyme og bliver
        ikke koblet til den enkelte bruger. Der bliver dog brugt IP adressen og
        andre browserrelaterede informationer i statistikken.
      </p>
      <h2>Oversigt over cookies</h2>
      <p>
        <ul>
          <li>
            CookieConsent, 1. part cookie med en varighed på 150 dage. Bruges
            til at gemme dit samtykke til brug af cookies.
          </li>
          <li>
            amp_[id], 3. parts cookie med en varighed på et år. Bruges af{" "}
            <a href="https://amplitude.com/">Amplitude</a> til statistik.
          </li>
        </ul>
      </p>
      <p>
        Du kan til en hver tid slette cookies og dermed dit samtykke til brug af
        cookies.
      </p>
    </SectionLayout>
  </Layout>
);

export default Cookies;
