import React, {useEffect} from "react";
import Layout from "../../components/layout"
import SectionLayout from "../../components/sectionLayout";
import styles from "./audio.module.scss";

const GangINedloebsrefleksen: React.FC = () => {
  return (
    <Layout title='Gang i nedløbsrefleksen'>
      <SectionLayout>
        <h3 className="attention">
          Jeg har designet denne lydfil til at aktivere din nedløbsrefleks - og jo flere gange du bruger den, jo mere effektiv bliver den!
        </h3>
        <p>
          Din nedløbsrefleks er en betinget refleks, den aktiveres af små cues dit barn sender dig, sammen med den taktile stimuli (nulren på brystvorten, dét at din baby sutter osv.).
          <br />Men! Vi mennesker har også en superkraft i form af vores forestillingsevne 💭
          <br />Og den bruger vi netop i denne lydfil - det betyder at du får endnu en vej til en hurtigere nedløbsrefleks og dermed en mere rolig amning 😌🥰
          Og hvem vil ikke gerne det?? 😅
        </p>
        <p>
          Husk: en langsom nedløbsrefleks kan også hænge sammen med en lav mælkeproduktion. Opsøg altid hjælp hos en kvalificeret fagperson hvis du er i tvivl ❤️
        </p>
        <br /><br />
        <audio className={styles.playerWrapper} controls>
          <source src="./media/gang-i-nedloebsrefleksen.mp3"/>
        </audio>
        <br /><br /><br />
      </SectionLayout>
    </Layout>)
}


export default GangINedloebsrefleksen
