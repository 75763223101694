export const menu = {
  menu: {
    items: [
      {
        title: "Forside",
        link: "/",
        splitAfter: true,
      },
      /*
      {
        title: "Moderskab i Balance",
        link: "/moderskab-i-balance"
      },
      {
        title: "Helende Moderskab",
        link: "/helende-moderskab"
      },
      {
        title: "Klub MindfulMOR",
        link: "/mindfulmor"
      },
      */
      {
        title: "Online Guides",
        link: "/guides",
      },
      {
        title: "Terapeutisk forløb",
        link: "/terapi",
      },
      {
        title: "Hjælp til amning",
        link: "/amning",
        splitAfter: true,
      },
      {
        title: "Om mig",
        link: "/om-mig",
      },
      {
        title: "Tilmeld nyhedsbrev",
        link: "/nyhedsbrev",
      },
      {
        title: "Kontakt mig",
        link: "/kontakt",
      },
    ],
  },
};
