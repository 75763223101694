export const frontpage = {
  intro: {
    title: "Iben Vutborg, psykolog og ammespecialist/IBCLC",
    subTitle: "barselspsykologen",
    description: `Få online psykologhjælp og guide til moderskabet`,
  },
  recommendations: {
    title: "Hvad siger andre?",
    items: [
      `Iben er den dygtigste psykolog jeg nogensinde har arbejdet med. Hun er sød og tillidsvækkende og utroligt dygtig. Hun har en stor faglighed og undersøger og udvikler sig gerne sammen med sine klienter. Jeg er desuden gigantisk fan af online sessioner. Selvom jeg var skeptisk i starten, må jeg erkende at det fungerer fantastisk og giver en utrolig frihed og fleksibilitet jeg elsker. Jeg kan på det varmeste anbefale Iben.`,
      `Kæmpe anbefaling her fra❤️ Iben har været en kæmpe hjælp på min rejse til at finde ind til mig selv. Hun har med sit rolige sind og utrolige evne givet mig den opbakning og de ord med på vejen, jeg havde brug for lige der hvor jeg stod. Hun har givet mig øvelser og metafore som har virket utrolig godt til at komme videre og det har gjort så meget. Øvelserne mellem aftalerne har for mig været utrolig vigtige, da jeg føler jeg kan arbejde aktivt med. Man kan altid skrive til hende med stort og småt mellem aftalerne og hun er altid klar til at hjælpe. Det har givet en utrolig ro at man bare har kunnet kontakte hende. Jeg har virkelig lært meget om mig selv allerede og jeg er kun lige begyndt at komme ud af min skal. Dette har været og er en stor investering i resten af mit liv, og jeg er så utrolig glad for at jeg valgte Iben til at hjælpe mig. Af hjertet tak og kæmpe anbefaling herfra❤️`,
      `Inden jeg fik hjælp fra Iben havde jeg allerede haft en ammevejleder på besøg, så jeg var i tvivl om jeg skulle prøve endnu en. Jeg valgte at give det et skud på trods af, at ammevejledningen også ville være som videokonsultation (midt i corona tiden). Dette fortryder jeg på ingen måde! Iben er først og fremmest yderste forstående og støttende i en svær tid. Hun er grundig og lader ikke bare en stå på egne ben efter konsultationen, men følger op på, hvordan det går. Det er rigtig dejligt og betryggende. Jeg fik rigtig god støtte og flere redskaber, som jeg kunne arbejde videre med. Uden Iben var vi ikke kommet så langt. Jeg kan varmt anbefale ammevejledning fra Iben - barselspsykologen.`,
      `Iben har hjulpet mig og vores familie, med at få luft i vores hverdag igen. Vores skuldre er igen kommet ned, vi har en plan for fremtiden, afstemte forventninger og nyt gåpåmod. Vi er så trygge i barselspsykologens hænder, vores samtaler, både på skrift og tale, har gjort en kæmpe forskel for vores fremtidige forløb og hverdag. Så alle de varmeste anbefalinger fra os i Hou.`,
      `Jeg har haft kontakt med Iben i mange år - det startede da jeg havde mange spørgsmål vedr. min dengang nyfødte søn, både mht. amning, men også alt det andet der rører sig i en nybagt mor. Jeg tog igen kontakt da jeg på meget drastisk vis fik vores datter. Uden hende ved jeg virkelig ikke hvad jeg skulle have gjort. Hun har hjulpet mig SÅ meget, jeg har fuldammet begge mine børn og langtidsammet bagefter. Iben har helt klart en kæmpe del i at det lykkedes. Derudover er hun fantastisk hvis man som mig, er i tvivl om alt! Hun er en stor støtte, samt en vidensbank, der ved alt om amning og børn - og det hun ikke ved finder hun ud af kort tid efter. Jeg ville helt klart kontakte hende igen, hvis jeg skulle have flere børn. KÆMPE ros og TAK for al din hjælp gennem årene!`,
      `Det er længe siden jeg har mødt en så hjertevarm person som dig. Du har en tydelig passion og oprigtighed, samtidig med at du er lydhør overfor problemstillingerne og situationen. Du har gjort en kæmpe forskel og hjulpet os helt ubeskriveligt, selvom det reelt var banale ændringer der skulle til.`,
    ],
  },
};
