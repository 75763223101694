import React, {useEffect} from "react";
import Layout from "../../components/layout";
import { kontakt } from "../../content/kontakt";
import SectionLayout from "../../components/sectionLayout";
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import styles from "./kontakt.module.scss";
import { encode } from "punycode";
import CheckIcon from '@mui/icons-material/Check';
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";

const Kontakt: React.FC = () => {

  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [message, setMessage] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);

  useEffect(() => {
    amplitude.getInstance().logEvent("Show page: kontakt");
  }, []);

  const apply = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          Name: encodeURI(name),
          Email: encodeURI(email),
          Phone: encodeURI(phone),
          Message: encodeURI(message),
        })
    };
    
    fetch('apply', requestOptions)
      .then(d => {
        setSuccess(true);
      });
  }

  return (
    <Layout title={kontakt.title}>
      <Helmet>
        <meta
          name="description"
          content="Kontakt mig direkte via kontaktformularen på denne side. Jeg besvarer din besked indenfor 24 timer. Få hjælp til dit moderskab og ammeproblemer hos Barselspsykologen."
        />
        <meta
          name="keywords"
          content="kontakt mig, kontaktformular, barselspsykologen, hjælp til moderskab, ammeproblemer, psykolog"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://barselspsykologen.dk/kontakt" />
        <title>Kontakt mig | Barselspsykologen</title>
      </Helmet>
      <SectionLayout altClassName={styles.contact}>
        {kontakt.description}
        <form autoComplete="off" onSubmit={apply}>
          <div>
            <TextField
              className={styles.small}
              id="standard-basic"
              label="Dit navn"
              variant="standard"
              required
              onChange={(evt) => setName(evt.target.value)}
            />
          </div>
          <div>
            <TextField
              className={styles.small}
              id="standard-basic"
              label="E-mail"
              variant="standard"
              required
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </div>
          <div>
            <TextField
              className={styles.small}
              id="standard-basic"
              label="Mobil"
              variant="standard"
              onChange={(evt) => setPhone(evt.target.value)}
            />
          </div>
          <div>
            <TextField
              id="standard-basic"
              multiline
              label="Kort beskrivelse af hvad du har brug for hjælp til"
              variant="standard"
              fullWidth
              onChange={(evt) => setMessage(evt.target.value)}
            />
          </div>
          <br />
          <br />
          Af hensyn til personfølsom data, gør jeg opmærksom på at formularen
          her ikke er krypteret, men har samme grad af fortrolighed som en
          personlig mail.
          <div className={styles.applyBtn}>
            <div>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
              >
                Send
              </Button>
            </div>
            {success && (
              <div className={styles.success}>
                <CheckIcon fontSize="large" />
                Din besked er sendt
              </div>
            )}
          </div>
        </form>
      </SectionLayout>
    </Layout>
  );
}

export default Kontakt
