import React, { useEffect } from "react";
import Layout from "../../components/layout";
import SectionLayout from "../../components/sectionLayout";
import styles from "./about.module.scss";
import amplitude from "amplitude-js";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ImageOfIben from "../../images/about.jpg";
import { Helmet } from "react-helmet";

const About: React.FC = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("Show page: om mig");
  }, []);

  return (
    <Layout title="Om mig">
      <Helmet>
        <meta
          name="description"
          content="Jeg er psykolog og IBCLC med fokus på moderskab og amning. Få støtte og vejledning til dit moderskab gennem mine erfaringer og viden."
        />
        <meta
          name="keywords"
          content="psykolog, IBCLC, moderskab, amning, barselspsykolog, moderskabsstøtte"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://barselspsykologen.dk/om-mig" />
        <title>Om mig | Barselspsykologen</title>
      </Helmet>
      <SectionLayout altClassName={styles.about}>
        <h3 className="attention">
          Hvor er det dejligt du har fundet vej til min side! Velkommen til!
        </h3>
        <div className={styles.image}>
          <img src={ImageOfIben} width="100%" />
        </div>
        <p>
          Jeg er først og fremmest mor til 4 dejlige unger, som spænder fra
          alderen 2 1/2 til 15 år!
        </p>
        <p>
          Starten på mit moderskab var brolagt med tvivl, usikkerhed, bekymring
          og intens kærlighed. Det lille menneske i mine arme betød alt for mig,
          og jeg ville så gerne gøre det absolut bedste for ham. Som man jo vil
          som mor!
        </p>
        <p>
          Jeg læste og læste og researchede og grublede. Al den
          informationssøgning gav også grobund for uendelige bekymringer og
          over-analysering af alt hvad jeg tænkte og gjorde. Sådan som vi
          mennesker har tendens til at gøre. Det betød desværre at jeg var
          virkelig meget til stede i hovedet og slet ikke brugte min
          opmærksomhed dér hvor den virkelig betød noget: i rummet mellem min
          baby og jeg. I stedet brugte jeg den på at forsøge at tøjle den
          overvældelse jeg gik med indeni, indtil den slugte al energi ud af
          mig. Det resulterede i en belastningsreaktion.
        </p>
        <p>
          Idag kan jeg se det var det, der skete. Derfor har jeg viet min tid
          til at hjælpe og støtte mødre, der som jeg, dengang for 15 år siden,
          føler sig uerfarne og usikre.
        </p>
        <p>
          Der er nemlig noget at gøre! Og det er måske, overraskende nok, ikke
          nødvendigvis flere facts og mere læsestof ;-)
        </p>

        <h2>Psykolog & IBCLC</h2>
        <p>
          Igennem min tid på psykologistudiet og som frivillig på Ammenet fandt
          jeg min passion for moderskabet og amningen. Så meget at mit
          psykologi-speciale kom til at handle om tilknytning og amning og jeg
          gik til IBCLC eksamen i 2020 oveni og bestod. Dermed blev jeg den
          første psykolog i Danmark, som også kunne kalde sig IBCLC.
        </p>
        <p>
          Når vi bliver mødre ændrer vi os fundamentalt. Det er ikke det vores
          samfund fortæller os, der skal ske - nej, vi skal “tilbage” i samme
          rolle som vi befandt os i før vi fik børn. Ikke desto mindre sker der
          fundamentale ændringer, helt på kognitivt niveau. Vores hjerner ændrer
          sig. Det kan ses og måles. Derfor er det heller ikke mærkeligt at
          mange af os får brug for hjælp og støtte undervejs. Det er meget at
          forholde sig til: at blive mor, ansvaret for et lille menneske,
          ensomheden, bekymringen, amning eller flaskegivning. Alt er nyt og
          intet føles som før.
        </p>
        <p>
          Og det er heller ikke meningen at det skal blive som før. Men det er
          heller ikke meningen at du skal opsluges af moderskabet eller at det
          skal efterlade dig overvældet og ensom.
        </p>
        <p>
          Du kan udvikle dig igennem og med dit moderskab. Og du kan blive tryg
          i dit moderskab!
        </p>

        <h3 className="attention">
          Det er derfor jeg er her - som psykolog, som IBCLC og som mor.
        </h3>
        <p>
          <span>
            Jeg brænder for at dele min erfaring, viden og ikke mindst brænder
            jeg for et kærligt og trygt moderskab. Dem, der kender mig, vil vide
            at mit <FavoriteIcon color="primary" fontSize="small" /> er hos og
            med dig.
          </span>
        </p>

        <br />
        <p>Ræk ud. Tag kontakt.</p>
        <p>
          Jeg har aldrig haft kontakt til mødre, som fortrød de rakte ud. Kun at
          de ville ønske, at de havde gjort det noget før.
        </p>
        <p>Kh. Iben</p>
      </SectionLayout>
    </Layout>
  );
};

export default About;
