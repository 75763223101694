import { red } from "@mui/material/colors";
import React from "react";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <h1>404 - Page Not Found</h1>
      <h2>
        Denne side findes ikke. Klik{" "}
        <a style={{ fontWeight: "bold", textDecoration: "underline" }} href="/">
          her
        </a>{" "}
        for at komme til forsiden
      </h2>
    </div>
  );
};

export default NotFound;
