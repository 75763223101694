import React, { useEffect } from "react";
import Layout from "../../components/layout";
import SectionLayout from "../../components/sectionLayout";
import SectionGroup from "../../components/sectionGroup";
import styles from "./products.module.scss";
import amplitude from "amplitude-js";
import { Button } from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import { motion } from "framer-motion";
import Iben1Preview from "../../images/mindfulmor_iben.jpeg";
import { Helmet } from "react-helmet";
import { useProducts } from "./useProducts";

const Products: React.FC = () => {
  const products = useProducts();
  const newsletterHref = "/nyhedsbrev";

  useEffect(() => {
    amplitude.getInstance().logEvent("Show page: Online Guides");
  }, []);

  return (
    <Layout title="Online guides">
      <Helmet>
        <meta
          name="description"
          content="Få øjeblikkelig hjælp med online guides om blid fravænning af amning og forståelse af din baby. Se udvalget her."
        />
        <meta
          name="keywords"
          content="online guides, psykolog, ammespecialist, blid fravænning af amning, forstå din baby, Barselspsykologen"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://barselspsykologen.dk/guides" />
        <title>Online Guides | Barselspsykologen</title>
      </Helmet>
      <SectionGroup altClassName={styles.products}>
        <SectionLayout>
          <p>
            <strong>
              Mine guides er designet til at hjælpe dig øjeblikkeligt.
            </strong>
            <br />
            <strong>Se udvalget herunder:</strong>
          </p>
        </SectionLayout>
        <SectionLayout>
          {products.map((product) => (
            <>
              <div id={product.anchorTag}></div>
              <motion.div
                initial={{ y: 50 }}
                whileInView={{ y: 0 }}
                transition={{
                  type: "spring",
                  bounce: 0.4,
                  duration: 0.8,
                }}
              >
                <div className={styles.productSummary}>
                  <h2>{product.longTitle}</h2>
                  <p>
                    <strong>{product.priceDescription}</strong>
                  </p>
                  <motion.div
                    className={styles.leftButton}
                    initial={{ scale: 0.9 }}
                    whileInView={{ scale: 1 }}
                    transition={{
                      type: "spring",
                      bounce: 0.2,
                      duration: 0.8,
                    }}
                  >
                    <Button
                      variant="contained"
                      href={product.saleUrl}
                      target="_blank"
                      size="large"
                    >
                      Få adgang nu
                    </Button>
                    {product.previewUrl && product.previewLabel && (
                      <Button
                        variant="contained"
                        href={product.previewUrl}
                        target="_blank"
                        size="large"
                      >
                        {product.previewLabel}
                      </Button>
                    )}
                  </motion.div>
                  {product.description}
                  <motion.div
                    className={styles.leftButton}
                    initial={{ scale: 0.9 }}
                    whileInView={{ scale: 1 }}
                    transition={{
                      type: "spring",
                      bounce: 0.2,
                      duration: 0.8,
                    }}
                  >
                    <Button
                      variant="contained"
                      href={product.pageUrl}
                      size="large"
                    >
                      Læs mere
                    </Button>
                  </motion.div>
                </div>
              </motion.div>
            </>
          ))}
          <motion.div
            initial={{ scale: 1 }}
            whileInView={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 50,
            }}
          >
            <SectionLayout altClassName={styles.signature}>
              <img src={Iben1Preview} />
              <strong>
                Har du nogle spørgsmål, så kontakt mig endelig på
                iben@barselspsykologen.dk
              </strong>
            </SectionLayout>
          </motion.div>
        </SectionLayout>
      </SectionGroup>
    </Layout>
  );
};

export default Products;
