import React from "react";
import SectionLayout from "../../components/sectionLayout";
import SectionGroup from "../../components/sectionGroup";
import styles from "./products.module.scss";
import { ProductInfo } from "./useProducts";
import { motion } from "framer-motion";
import { Button } from "@mui/material";
import Iben1Preview from "../../images/mindfulmor_iben.jpeg";

interface ProductPageProps {
  product: ProductInfo;
  children: React.ReactNode;
}

export const ProductPage: React.FC<ProductPageProps> = (props) => {
  return (
    <SectionGroup altClassName={styles.products}>
      <SectionLayout>
        <div id={props.product.anchorTag}></div>
        <motion.div
          initial={{ y: 50 }}
          whileInView={{ y: 0 }}
          transition={{
            type: "spring",
            bounce: 0.4,
            duration: 0.8,
          }}
        >
          <div className={styles.product}>
            <h2>{props.product.longTitle}</h2>
            <p>
              <strong>{props.product.priceDescription}</strong>
            </p>
            <motion.div
              className={styles.leftButton}
              initial={{ scale: 0.9 }}
              whileInView={{ scale: 1 }}
              transition={{
                type: "spring",
                bounce: 0.2,
                duration: 0.8,
              }}
            >
              <Button
                variant="contained"
                href={props.product.saleUrl}
                target="_blank"
                size="large"
              >
                Få adgang nu
              </Button>
              {props.product.previewUrl && props.product.previewLabel && (
                <Button
                  variant="contained"
                  href={props.product.previewUrl}
                  target="_blank"
                  size="large"
                >
                  {props.product.previewLabel}
                </Button>
              )}
            </motion.div>
            {props.product.description}
            {props.children}
          </div>
        </motion.div>
        <motion.div
          initial={{ scale: 1 }}
          whileInView={{ scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 50,
          }}
        >
          <SectionLayout altClassName={styles.signature}>
            <img src={Iben1Preview} />
            <strong>
              Har du nogle spørgsmål, så kontakt mig endelig på
              iben@barselspsykologen.dk
            </strong>
          </SectionLayout>
        </motion.div>
      </SectionLayout>
    </SectionGroup>
  );
};
