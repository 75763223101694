import React from "react"

import Layout from "../../components/layout"
import SectionLayout from "../../components/sectionLayout";
import styles from "./audio.module.scss";

const EnRustningAfVrede = () => (
  <Layout title="En rustning af vrede">
    <SectionLayout>
      <audio className={styles.playerWrapper} controls>
        <source src="./media/en-rustning-af-vrede.mp3" />
      </audio>
    </SectionLayout>
  </Layout>
)

export default EnRustningAfVrede