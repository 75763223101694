import React, { useEffect } from "react";
import Layout from "../../components/layout";
import styles from "./frontpage.module.scss";
import ChatIcon from "@mui/icons-material/Chat";
import SectionLayout from "../../components/sectionLayout";
import { frontpage } from "../../content/frontpage";
import SectionGroup from "../../components/sectionGroup";
import { Button, Grid } from "@mui/material";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import amplitude from "amplitude-js";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { useProducts } from "../onlineProducts/useProducts";

const Frontpage: React.FC = () => {
  const onlineProducts = useProducts();

  useEffect(() => {
    amplitude.getInstance().logEvent("Show frontpage");
  }, []);

  const traceKursusClick = () => {
    amplitude
      .getInstance()
      .logEvent("Frontpage: read more about Helende Moderskab");
  };

  const traceModerskabIBalanceClick = () => {
    amplitude
      .getInstance()
      .logEvent("Frontpage: read more about Moderskab i Balance");
  };

  const traceModerskabIBalanceBuyClick = () => {
    amplitude.getInstance().logEvent("Frontpage: buy Moderskab i Balance");
  };

  const traceNyhedsbrevClick = () => {
    amplitude.getInstance().logEvent("Frontpage: sign up for newsletter");
  };

  const traceMindfulMorClick = () => {
    amplitude.getInstance().logEvent("Frontpage: sign up for MindfulMOR");
  };

  const traceProdukterClick = () => {
    amplitude
      .getInstance()
      .logEvent("Frontpage: read more about Online Produkter");
  };

  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content="Få skræddersyet hjælp af psykolog og ammespecialist Iben Vutborg. Få instant access til online guides og terapeutiske forløb. Åbent for nye klienter."
        />
        <meta
          name="keywords"
          content="psykolog, ammespecialist, IBCLC, online guides, terapeutisk forløb, ammevejledning, online psykolog samtaler, barselspsykologen, nordjylland"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://barselspsykologen.dk" />
        <title>Barselspsykologen | Psykolog og Ammespecialist</title>
      </Helmet>
      <SectionGroup altClassName={styles.bgImg}>
        <SectionLayout altClassName={styles.sectionIntro}>
          <div className={styles.intro}>
            <div className={styles.introduction}>
              <h1>
                <motion.div
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{
                    type: "spring",
                    bounce: 0.2,
                    duration: 0.6,
                    delay: 0.2,
                  }}
                >
                  {frontpage.intro.subTitle}
                </motion.div>
              </h1>
              <h2>{frontpage.intro.title}</h2>
            </div>
            <h2 className={styles.description}>
              {frontpage.intro.description}
            </h2>
            <div className={styles.photo}>
              <div className={styles.photoIben}></div>
            </div>
            {false && (
              <div className={styles.contact}>
                <Button
                  startIcon={<ContactMailIcon />}
                  variant="contained"
                  color="primary"
                  href="/kontakt"
                >
                  Kontakt mig
                </Button>
              </div>
            )}
          </div>
        </SectionLayout>
        {true && (
          <motion.div
            initial={{ y: 50 }}
            whileInView={{ y: 0 }}
            transition={{
              type: "spring",
              bounce: 0.4,
              duration: 1,
            }}
          >
            <SectionLayout altClassName={styles.primary}>
              <h2>Få hjælp med online guides</h2>
              <h3>Du kan få hjælp med det samme med mine online guides:</h3>
              {onlineProducts.map((product) => (
                <>
                  <p style={{ display: "flex" }}>
                    <span style={{ paddingRight: "10px" }}>
                      <FavoriteIcon color="primary" fontSize="medium" />
                    </span>
                    <div>
                      {product.longTitle}
                      <br />
                      <span style={{ fontSize: 16 }}>
                        {product.priceDescription}
                      </span>
                    </div>
                  </p>
                  <motion.div
                    initial={{ scale: 0.8 }}
                    whileInView={{ scale: 1 }}
                    transition={{
                      type: "spring",
                      bounce: 0.4,
                      duration: 0.8,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      href={product.pageUrl}
                      onClick={traceProdukterClick}
                      style={{ marginLeft: "44px", marginBottom: "10px" }}
                    >
                      Læs mere
                    </Button>
                  </motion.div>
                </>
              ))}
            </SectionLayout>
            <SectionLayout altClassName={styles.spacer}>&nbsp;</SectionLayout>
          </motion.div>
        )}
        {false && (
          <motion.div
            initial={{ y: 50 }}
            whileInView={{ y: 0 }}
            transition={{
              type: "spring",
              bounce: 0.4,
              duration: 1,
            }}
          >
            <SectionLayout altClassName={styles.secondary}>
              <h2>Bliv Klub MindfulMOR medlem NU!</h2>
              <p>
                <br />
                Klub MindfulMOR er en digital mødregruppe og et
                undervisningsbibliotek, der giver dig den direkte vej til mere
                ro, tryghed og trivsel i dit moderskab.
                <br />
              </p>
              <motion.div
                initial={{ scale: 0.8 }}
                whileInView={{ scale: 1 }}
                transition={{
                  type: "spring",
                  bounce: 0.4,
                  duration: 0.8,
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  href="/mindfulmor"
                  onClick={traceMindfulMorClick}
                >
                  Læs mere
                </Button>
              </motion.div>
            </SectionLayout>
            <SectionLayout altClassName={styles.spacer}>&nbsp;</SectionLayout>
          </motion.div>
        )}
        {false && (
          <motion.div
            initial={{ y: 50 }}
            whileInView={{ y: 0 }}
            transition={{
              type: "spring",
              bounce: 0.4,
              duration: 1,
            }}
          >
            <SectionLayout altClassName={styles.secondary}>
              <Grid container>
                <Grid item md={12}>
                  <div className={styles.product}>
                    <h2>Undgå stress og overvældelse i barslen</h2>
                    <p>
                      Undgå stress og overvældelse i barslen, så du og dit barn
                      kan få den bedste start på tilværelsen sammen{" "}
                      <FavoriteIcon color="primary" fontSize="large" />
                    </p>
                    <motion.div
                      initial={{ scale: 0.8 }}
                      whileInView={{ scale: 1 }}
                      transition={{
                        type: "spring",
                        bounce: 0.4,
                        duration: 0.8,
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        href="/nyhedsbrev"
                        onClick={traceNyhedsbrevClick}
                      >
                        Få det her!
                      </Button>
                    </motion.div>
                  </div>
                </Grid>
              </Grid>
            </SectionLayout>
            <SectionLayout altClassName={styles.spacer}>&nbsp;</SectionLayout>
          </motion.div>
        )}
        {false && (
          <>
            <SectionLayout altClassName={styles.kursus}>
              <Grid container>
                <Grid item md={12}>
                  <div className={styles.product}>
                    <h2>
                      Åben for tilmelding til online gruppeforløbet: Helende
                      Moderskab
                    </h2>
                    <p>
                      Vil du med på en 12-ugers udviklingsrejse, som hjælper dig
                      ud af hovedet og ind i nuet, så du kan være ægte tilstede
                      i relationen med dit barn?
                    </p>
                    <p>Opstart 14. februar, 2022 - tilmeld dig nu!</p>
                    <Button
                      variant="outlined"
                      color="secondary"
                      href="/helende-moderskab"
                      onClick={traceKursusClick}
                    >
                      læs mere
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </SectionLayout>
            <SectionLayout altClassName={styles.spacer}>&nbsp;</SectionLayout>
          </>
        )}
        {false && (
          <motion.div
            initial={{ y: 50 }}
            whileInView={{ y: 0 }}
            transition={{
              type: "spring",
              bounce: 0.4,
              duration: 1,
            }}
          >
            <SectionLayout altClassName={styles.primary}>
              <Grid container>
                <Grid item md={12}>
                  <div className={styles.product}>
                    <h2>Lukket for nye klienter!</h2>
                    <h3>
                      Der har været stor efterspørgsel efter mine ydelser og
                      ikke tider nok i min kalender!
                    </h3>
                    <p>
                      Tag et kig på mit nye online univers{" "}
                      <b>Klub MindfulMOR.</b>
                    </p>
                    <motion.div
                      initial={{ scale: 0.8 }}
                      whileInView={{ scale: 1 }}
                      transition={{
                        type: "spring",
                        bounce: 0.4,
                        duration: 0.8,
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        href="/mindfulmor"
                        onClick={traceMindfulMorClick}
                      >
                        Læs mere
                      </Button>
                    </motion.div>
                  </div>
                </Grid>
              </Grid>
            </SectionLayout>
            <SectionLayout altClassName={styles.spacer}>&nbsp;</SectionLayout>
          </motion.div>
        )}
        <motion.div
          initial={{ y: 50 }}
          whileInView={{ y: 0 }}
          transition={{
            type: "spring",
            bounce: 0.4,
            duration: 1,
          }}
        >
          <SectionLayout altClassName={styles.secondary}>
            <Grid container>
              <Grid item md={6}>
                <div className={styles.product}>
                  <h2>Online psykolog hjælp</h2>
                  <p>
                    Føler du, at du sidder fast i følelser, tanker eller
                    handlemønstre og at lige meget hvad du gør, slipper de ikke
                    deres tag? Længes du efter at skabe andre minder med dit
                    barn? I dag er en perfekt dag til at skabe noget nyt! Tag
                    det levede liv tilbage nu!
                  </p>
                  <motion.div
                    initial={{ scale: 0.8 }}
                    whileInView={{ scale: 1 }}
                    transition={{
                      type: "spring",
                      bounce: 0.4,
                      duration: 0.8,
                    }}
                  >
                    <Button variant="outlined" color="secondary" href="/terapi">
                      læs mere
                    </Button>
                  </motion.div>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={styles.product}>
                  <h2>Ammevejledning</h2>
                  <p>
                    Amning er mere end mælk. Hos mig får du helt konkret hjælp
                    til amningen, samtidig med at der er rum til at sætte ord på
                    de følelser, som fylder når amningen er svær. Du får din
                    helt egen personlige livline til mig, som du frit kan bruge
                    når du oplever behov for det!
                  </p>
                  <motion.div
                    initial={{ scale: 0.8 }}
                    whileInView={{ scale: 1 }}
                    transition={{
                      type: "spring",
                      bounce: 0.4,
                      duration: 0.8,
                    }}
                  >
                    <Button variant="outlined" color="secondary" href="/amning">
                      læs mere
                    </Button>
                  </motion.div>
                </div>
              </Grid>
            </Grid>
          </SectionLayout>
        </motion.div>
        <SectionLayout altClassName={styles.recommendations}>
          <h2>{frontpage.recommendations.title}</h2>
          {frontpage.recommendations.items.map((value, index) => (
            <motion.div
              initial={{ y: 20, scale: 0.9 }}
              whileInView={{
                opacity: 1,
                y: 0,
                scale: 1,
              }}
              transition={{ type: "spring", stiffness: 300, damping: 50 }}
              key={index}
              className={styles.item}
            >
              <ChatIcon fontSize="large" className={styles.icon} />
              {value}
            </motion.div>
          ))}
        </SectionLayout>
      </SectionGroup>
    </Layout>
  );
};

export default Frontpage;
