import React from "react"
import PropTypes from "prop-types"

interface Props {
    children: React.ReactNode;
    altClassName: any | undefined;
}

const SectionGroup: React.FC<Props> = props => {
  return (
      <div className={`${props.altClassName ?? ''}`}>
          {props.children}
      </div>
  )
}

export default SectionGroup