import React from "react";

import Layout from "../../components/layout";
import SectionLayout from "../../components/sectionLayout";
import "../../styles/content.scss";
import { Helmet } from "react-helmet";

const Persondatapolitik = () => (
  <Layout title="Persondatapolitik">
    <Helmet>
      <title>Personalepolitik</title>
      <meta name="robots" content="nosnippet" />
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <SectionLayout>
      <p>
        Vi indhenter kun persondata i de tilfælde, hvor dette skulle være
        relevant for os, og vi vil kun indhente persondata, hvis det er relevant
        for din aktivitet hos Barselspsykologen.
      </p>
      <p>
        Ved indsamling, behandling og anvendelse af dine persondata overholder
        vi altid alle relevante lovbestemmelser.
      </p>
      <p>
        Vi vil kun opbevare dine persondata, så længe vi enten er pålagt en
        juridisk forpligtelse hertil, eller så længe det er relevant for den
        hensigt, hvormed de blev indsamlet.
      </p>

      <h3>Oplysninger vi indsamler</h3>
      <p>
        Hvis du ønsker at købe og modtage et produkt eller en ydelse fra os, har
        vi brug for at indsamle visse persondata for at kunne gennemføre handlen
        og for at kunne tilbyde dig vores services. Vi kan bruge cookies til at
        holde styr på indholdet i din indkøbskurv, mens du bruger vores webshop.
      </p>
      <p>
        Vi kan indhente oplysninger som f.eks. navn, e-mailadresse,
        telefonnummer og betalingsoplysninger.
      </p>
      <p>
        Barselspsykologen indsamler og behandler dine persondata, når du
        foretager dig følgende:
        <ul>
          <li>
            Besøger vores hjemmeside barselspsykologen.dk eller
            barselspsykologen.simplero.com
          </li>
          <li>
            Gennemfører et køb af vores produkter via
            barselspsykologen.simplero.com
          </li>
          <li>Opretter en konto via barselspsykologen.simplero.com</li>
          <li>Tilmelder dig vores nyhedsbrev</li>
          <li>Sender os spørgsmål, reklamationer eller feedback</li>
        </ul>
      </p>

      <h3>Den dataansvarlige</h3>
      <p>
        Den dataansvarlige for indsamling, behandling og anvendelse af dine
        personoplysninger på barselspsykologen.dk og
        barselspsykologen.simplero.com er Barselspsykologen, Fladkærvej 22, 9370
        Hals, cvr nr. 40972625
      </p>

      <h3> Behandlingsgrundlag og formål</h3>
      <p>
        Dine almindelige kontaktoplysninger som navn og e-mail indhenter vi for
        at kunne levere det produkt eller den ydelse, som du har købt hos os.
        Din e-mailadresse indhenter vi for at kunne sende dig en
        ordrebekræftelse samt en leveringsbekræftelse.
      </p>
      <p>
        Når du betaler for dit produkt eller din ydelse indsamler vi dit navn,
        dine kortdata og IP-adresse. De oplysninger, der indsamles i forbindelse
        med betalingstransaktionen, anvendes og gemmes kun til
        betalingsafvikling og opfyldelse af den indgåede aftale. Ved
        gennemførelse af betalinger, vil nogle af dine data blive videregivet
        til Stripe og Dinero, inklusiv information der er nødvendig for at
        gennemføre eller understøtte betalingen, såsom det totale købsbeløb og
        faktureringsinformation.
      </p>
      <p>
        Hvis du under registreringsprocessen eller på vores hjemmeside har givet
        os dit samtykke hertil, bruger vi din e-mailadresse og navn til
        udsendelse af et nyhedsbrev. Du kan til enhver tid til- og afmelde dig
        nyhedsbrevet. Alle nyhedsbreve indeholder også et link til afmelding.
        Hvis behandling af persondata er baseret på dit samtykke, har du til
        enhver tid ret til at tilbagekalde samtykket. Sendgrid bruges til
        udsendelse af mails vedr. nyhedsbrev, og navn og mail bliver derfor
        videregivet til Sendgrid.
      </p>
      <p>
        Hvis du ikke ønsker at opgive de persondata, som er påkrævet for at
        gennemføre et køb af et produkt, vil du desværre ikke have mulighed for
        at købe produkter af os på vores hjemmeside eller via
        barselspsykologen.simplero.com.
      </p>
      <p>
        Dine oplysninger (undtagen din e-mailadresse, hvis du har givet samtykke
        til behandling heraf med henblik på modtagelse af tilbud) vil blive
        slettet 5 år efter, at du har modtaget din vare eller ydelse.
      </p>

      <h3>Andre modtagere af personoplysninger</h3>
      <p>
        Vi sælger ikke dine persondata til tredjemand, og vi overfører ikke dine
        persondata til tredjelande.
      </p>
      <p>
        Vi har vores hjemmeside hos Simply, vores digitale produkt platform hos
        Simplero, betalingssystem gennem Stripe og bruger Sendgrid til mail
        udsendelse, som fungerer som vores databehandler. Alle persondata som du
        oplyser på hjemmesiden barselspsykologen.dk vil blive opbevaret i
        Simplys datacentre samt hos Sendgrid såfremt det skal bruges til
        mailudsendelse. Handel og brug af hjemmesiden
        barsekspsykologen.simplero.com eller via Simplero APP vil blive
        opbevaret via Simpleros datacentre.
      </p>
      <p>
        Vi anvender eksterne virksomheder som leverandører for at levere vores
        ydelser bedst muligt. Disse eksterne leverandører er databehandlere og
        behandler i visse tilfælde personoplysninger i forbindelse med deres
        levering af ydelser til os. Vores databehandlere behandler kun
        personoplysninger efter vores instruks og i overensstemmelse med
        lovgivningens krav til databehandlere.
      </p>
      <p>
        Vi har indgået databehandleraftaler med vores databehandlere, hvilket er
        vores garanti for, at de overholder gældende regler om beskyttelse af
        dine personoplysninger.
      </p>

      <h3>Dine rettigheder</h3>
      <p>
        Som den registrerede har du en række rettigheder, som vi til enhver tid
        skal sikre opfyldelse af. Du har ret til at anmode os om følgende:
        <ul>
          <li>At få adgang til og få rettet/ændret dine persondata</li>
          <li>At få slettet persondata</li>
        </ul>
      </p>
      <p>
        Du har derudover ret til at protestere over behandlingen af dine
        personlige data, og du har ret til at indgive klage til en
        databeskyttelsesmyndighed.
      </p>
      <p>
        Ønsker du ikke længere, at vi skal behandle dine personoplysninger,
        eller at vi skal begrænse behandlingen af dine personoplysninger, kan du
        også sende os en anmodning herom til vores e-mailadresse
        kontakt@barselspsykologen.dk.
      </p>
    </SectionLayout>
  </Layout>
);

export default Persondatapolitik;
