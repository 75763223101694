import React, { useEffect } from "react";
import Layout from "../../components/layout";
import styles from "./products.module.scss";
import amplitude from "amplitude-js";
import { Button } from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import { motion } from "framer-motion";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Helmet } from "react-helmet";
import { ProductPage } from "./productPage";

export const productInfo = {
  anchorTag: "4",
  title: "Få en dejligere jul 🎄",
  longTitle: "🎄 Få en dejligere jul 🎄",
  priceDescription: `Intro pris: 279 kr. inklusiv moms`,
  description: (
    <>
      <p>
        🤯 Føler du, at du halser efter for at indfri andres forventninger og at
        det er svært at mærke ✨din julemagi✨ i maven?
        <br />
        🤯 Føler du dig usikker på hvordan du og din baby skal komme igennem
        julen uden fuldstændig flossede nervesystemer?
        <br />
        🤯 Føler du, at du bliver fanget i tankemylder og overvældelse, og ved
        du ikke hvordan du skal træde ud af det?
      </p>
      <p>Sænk skuldrene nu, mama! Denne guide er til dig 🌟</p>
    </>
  ),
  saleUrl:
    "https://barselspsykologen.simplero.com/cart/218508-Faa-en-dejligere-jul",
  pageUrl: "/guides/faa-en-dejligere-jul",
  previewLabel: "Se de første 2 videoer gratis",
  previewUrl:
    "https://barselspsykologen.simplerosites.com/courses/90450-faa-en-dejligere-jul/lessons/1382669-velkommen",
};

const FaaEnDejligereJul: React.FC = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("Show page: Få en dejligere jul");
  }, []);

  return (
    <Layout title={productInfo.title}>
      <Helmet>
        <meta name="description" content="Få en dejligere jul." />
        <meta
          name="keywords"
          content="Dejlig jul med børn, online guides, psykolog, ammespecialist, Barselspsykologen"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://barselspsykologen.dk/guides/faa-en-dejligere-jul"
        />
        <title>Få en dejligere jul | Barselspsykologen</title>
      </Helmet>
      <ProductPage product={productInfo}>
        <p>
          I denne juleguide lærer du, at tøjle din hjerne og spotte hvad der
          dræner dig, så du kan bruge din energi lige dér, hvor den giver dig
          det, du allerhelst vil have{" "}
          <FavoriteIcon color="primary" fontSize="small" />
        </p>
        <motion.div
          className={styles.leftButton}
          initial={{ scale: 0.9 }}
          whileInView={{ scale: 1 }}
          transition={{
            type: "spring",
            bounce: 0.2,
            duration: 0.8,
          }}
        >
          <Button
            variant="contained"
            href={productInfo.previewUrl}
            target="_blank"
            size="large"
          >
            Se listen af indholdet her
          </Button>
        </motion.div>
        <br />
        <h3>I dette juletema får du:</h3>
        <p>
          <strong>
            🎧 Lydfiler, som giver dig klarhed omkring hvad du mon egentligt
            jagter i juletiden - eller løber fra!
          </strong>{" "}
          Du får stillet skarpt på hvad julen egentligt handler om for dig, og
          hvad det er du og din familie skal bruge energi på, og hvad der måske
          kan gives slip på.
        </p>
        <p>
          <strong>📝 Skriftlige guides</strong>, som giver dig tips og tricks
          til at komme bedst gennem julen med baby eller et lille barn.
        </p>
        <p>
          <strong>🗝️ Nøglen til at låse op for dit indre kompas 🧭</strong> Her
          kommer nøglen i form af en kort skriftlig artikel og to
          refleksionskemaer. Når du har dit kompas, så er du endnu tættere på en
          jul (og et liv) med endnu flere stunder, som varmer helt ned i maven.
        </p>
        <p>
          <strong>
            🎧 En lydfil-samling med teknikker og øvelser, som får din hjerne ud
            af overdrive 🤯👉😌
          </strong>
          <br />
          Samlingen indeholder 5 lydfiler, med teknikker som hjælper din hjerne
          ud af overvældelse! Dem her har vi alle brug for! Lydfilerne er korte
          og nemme og - det bedste - hurtige at lære, så du kan høste alle
          frugterne hurtigt! Øvelserne her er geniale til sådan nogle som mig,
          som let kan blive fanget i tanker og “TODO TODO TODO”. Jeg bruger
          flere af dem dagligt i perioder, og de er guld at have i den indre
          værktøjskasse!
        </p>
        <p>
          <strong>
            🎁 I lydfilssamlingen er der en GAVE i form af DIN HJERNES
            PAUSEKNAP!
          </strong>{" "}
          En ekstra 6. Lydfil, som lærer dig en essentiel skill: nemlig at træde
          ud af dine tanker og give slip på dem 💭
        </p>
        <p>
          <strong>🎁😍</strong> Som en{" "}
          <strong>
            EKSTRA GAVE får du med guiden “Få en dejligere jul” OGSÅ en LIVE med
            mig d. 14. December
          </strong>
          , hvor du lærer hvorfor det er den ultimative kærlighedserklæring at
          vise andre vores grænser - også selvom det absolut ikke føles sådan
          (det gør det i hvert fald ikke for mig 🙈). Du vil også lære at spotte
          sunde grænser, både hos dig selv og hos andre, og du lærer
          selvfølgelig hvordan du begynder at sætte grænser, hvis den blotte
          tanke om det lige nu giver dig hjertebanken og ondt i maven ❤️
        </p>
        <p>
          Vi afslutter liven med en Q&A, hvor du kan deltage anonymt. Du kan
          stille spørgsmål i chatten live eller indsende dem til mig på forhånd.
          Der er SVARGARANTI og det bliver skide hyggeligt! You don’t want to
          miss it!
          <br />
        </p>
        <p>
          Når liven er afsluttet, bliver optagelsen tilføjet til dit købte
          produkt "Få en dejligere jul". Og køber du efter liven, får du
          naturligvis også mulighed for at se den.
        </p>
        <p>
          Du får øjeblikkelig adgang til alt materialet efter køb og du kan se
          det igen og igen - også med din eventuelle partner.
        </p>
        <p>
          Indholdet er tilgængeligt via{" "}
          <a
            className={styles.externalRef}
            href="https://simplero.com/"
            target="_blank"
            rel="noreferrer"
          >
            Simplero platformen
          </a>
          , og kan tilgås både via en App eller fra browseren.
        </p>
        <p>
          <br />
          <strong>
            Din investering i en DEJLIGERE JUL: 279,- kr. inkl. moms
          </strong>
        </p>
        <motion.div
          className={styles.leftButton}
          initial={{ scale: 0.9 }}
          whileInView={{ scale: 1 }}
          transition={{
            type: "spring",
            bounce: 0.2,
            duration: 0.8,
          }}
        >
          <Button
            variant="contained"
            href={productInfo.saleUrl}
            target="_blank"
            size="large"
          >
            Få adgang nu
          </Button>
          <Button
            variant="contained"
            href={productInfo.previewUrl}
            target="_blank"
            size="large"
          >
            {productInfo.previewLabel}
          </Button>
        </motion.div>
      </ProductPage>
    </Layout>
  );
};

export default FaaEnDejligereJul;
