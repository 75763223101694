import React from "react"

export const content = {
  title: 'Terapeutisk forløb',
  offerTitle: 'Du får',
  priceTitle: 'Priser',
  description: (<>
    <p>At blive forældre er en udviklingsproces. Dine tidligere erfaringer og oplevelser står måske frem i et helt andet perspektiv, og kan optage pladsen og energien fra din egen trivsel, dit barn, din eventuelle partner og jeres fælles tilværelse.</p>
    <p>Måske er der flere <b>konflikter</b> mellem dig og din partner, imellem dig og din nærmeste familie eller måske føler du dig utilstrækkelig eller trist.</p>
    <p>Forælderskabet og livet på barsel kan være <b>ensomt</b> og rollen som mor eller far kommer ikke lige så hurtigt og naturligt, som du måske havde forestillet dig.</p>
    <p>Er det svært at finde ud af <b>hvem du er</b>, efter du er blevet forælder?</p>
    <p>Måske er det svært for dig at relatere dig til dit barn, eller dine tanker kredser om hvorvidt dine <b>tidligere erfaringer og følelserne fra din barndom</b> står i vejen for dit forhold til dit eget barn.</p>
    <p>Har du følelsen af at <b>tilknytningen</b> mellem dig og dit barn ikke er som den skal være?</p>
    <p>En terapeutisk samtale kan foregå som hjemmekonsultation, hos mig eller som videokonsultation over krypteret forbindelse.</p>
    <p>Er du i tvivl om hvorvidt en terapeutisk samtale eller et forløb er vejen for dig, så tag kontakt. Jeg sidder klar til at tage en uforpligtende snak med dig.</p>
  </>),
  offer: [
    'Et trygt rum hvor du sætter dagsordnen og jeg står ved din side til at guide dig igennem den.',
    'Mulighed for at forstå dine vaner og hvad der bremser din udvikling',
    'Hjælp til at skabe den forandring du ønsker',
    'Forløb skræddersyet dig og dine behov',
  ],
  prices: [
    {
      title: 'Hjemmekonsultation',
      description: 'af 1 times varighed, 950 kr.',
      additionalTitle: 'Efterfølgende 30 minutter, 450 kr.',
    },
    {
      title: 'Videokonsultation',
      description: 'af 1 times varighed, 795 kr.',
      additionalTitle: 'Efterfølgende 30 minutter, 350 kr.',
    }
  ]
};
