import React, { useEffect } from "react";

import Layout from "../components/layout";
import { content } from "../content/product/terapi";
import SectionLayout from "../components/sectionLayout";
import { Button } from "@mui/material";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";

const Terapi: React.FC = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("Show page: terapi");
  }, []);

  return (
    <Layout title={content.title}>
      <Helmet>
        <meta
          name="description"
          content="Få professionel terapeutisk støtte som nybagt mor. Jeg tilbyder online terapi, med personlig skriftlig støtte mellem sessioner."
        />
        <meta
          name="keywords"
          content="psykolog, online samtaler, online terapi, efterfødselsreaktion, belastningsreaktion, depression, efterfødselsdepression, angst, efterfødselsangst, act terapi, kognitiv terapi, terapeutisk støtte, video samtaler, barselspsykolog"
        />
        <meta name="robots" content="index, follow"></meta>
        <link rel="canonical" href="https://barselspsykologen.dk/terapi" />
        <title>Terapeutisk Forløb | Barselspsykologen</title>
      </Helmet>
      <SectionLayout>
        <p>
          Når du bliver mor igangsættes en <b>rivende mental udvikling</b>. Din
          hjerne bliver pludselig en mester i at se potentielle{" "}
          <b>farer, perspektiver og udfald</b>. Den bliver også mere fintfølende
          i <b>aflæsning af følelser</b>. Det er nærmest som om den får en{" "}
          <b>ny dimension</b>. Alt sammen for at{" "}
          <b>sikre dit lille barns overlevelse!</b>
        </p>
        <p>
          Måske opdager du det ikke lige med det samme.{" "}
          <b>Det kan komme snigende lidt efter lidt</b>. For andre{" "}
          <b>rammer det hårdt</b> som et slag i ansigtet fra den ene dag til den
          anden.
        </p>
        <p>
          Men den udvikling efterlader dig forandret.{" "}
          <b>Du bliver aldrig den samme</b> person, som du var, før du blev mor.
          På godt og på ondt.
        </p>

        <h3 className="attention">
          At blive mor er ikke nødvendigvis sådan, som du forestillede dig det
          ville være.
        </h3>

        <p>
          Det kan være <b>sværere, vildere og langt mere krævende</b> end du
          nogensinde havde forestillet dig. En følelse af{" "}
          <b>dårlig samvittighed</b> kan for mange blive en fast{" "}
          <b>følgesvend i moderskabet</b>. Det kan samtidig også være mere{" "}
          <b>magisk og mere kærlighedsfyldt</b> end du nogensinde havde
          forventet. Ambivalensen kan være svær at rumme!
        </p>

        <p>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            href="terapi#priser"
          >
            Få hjælp nu
          </Button>
        </p>

        <br />

        <h2>Kan du genkende oplevelser som disse?</h2>
        <ul>
          <li>
            Du oplever, at det kan være <b>svært at finde plads</b> både til
            dig, <b>dine tanker, følelser og behov</b>, samtidig med at du
            opfylder dit barns behov for <b>tryghed og nærhed</b>.
          </li>
          <li>
            Du føler <b>ikke du lever op til</b> dine egne standarder i
            hverdagen og en følelse af <b>dårlig samvittighed forfølger dig</b>.
          </li>
          <li>
            Det kan føles som om <b>du har mistet dig selv</b>, efter du er
            blevet mor.
          </li>
          <li>
            Du kan føle dig overvældet af egne{" "}
            <b>oplevelser fra din egen barndom</b>, som pludselig står frem i et
            andet lys.
          </li>
          <li>
            Du reagerer måske på måder, som du, uanset hvor meget du prøver,{" "}
            <b>ikke føler du kan komme ud af</b>.
          </li>
          <li>
            Du kan <b>føle dig fanget</b> af tanker og følelser omkring dit barn
            eller dig selv, som <b>kan virke skræmmende</b> og som føles som om
            de <b>sidder fast og kører i ring</b>.
          </li>
          <li>
            Måske føler du dig <b>trist, ensom eller vred</b> det meste af
            tiden, og du oplever at <b>følelsen har hægtet sig fast</b> i dig og
            ikke vil slippe.
          </li>
        </ul>
        <p>
          Hvis du kan genkende noget af det ovenstående, så{" "}
          <b>vid at du ikke er alene</b>.
        </p>
        <h3 className="attention">
          Rigtig mange mødre kan genkende disse oplevelser.
        </h3>

        <p>
          Men det er ikke sådan det behøver være.{" "}
          <b>Du kan skabe noget andet!</b>
        </p>

        <p>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            href="terapi#priser"
          >
            Få hjælp nu
          </Button>
        </p>

        <br />

        <p>
          Fællesnævneren for de klienter jeg ser, er at de ønsker de havde{" "}
          <b>rakt ud efter hjælp tidligere</b>. Vi får aldrig vores børns første
          år tilbage. <b>Dit liv med dit barn kan være anderledes</b>. Dit
          forhold med dit barn behøver ikke være præget af disse svære følelser
          og oplevelser.
        </p>

        <p>
          Da jeg stod som nybagt mor for mange år siden,{" "}
          <b>mistede jeg fornemmelse af hvem jeg var</b>. Jeg endte med at
          forsvinde ind i mine tanker, ind i min hjerne, for at løse og
          overtænke de problemer og udfordringer jeg oplevede med et lille barn.
          Det <b>fjernede mig endnu længere væk</b> fra det jeg elskede mest -
          nemlig min søn og min mand. Min vej tilbage var lang og med masser af
          krumspring. <b>Men hvor er jeg taknemmelig</b> over jeg gik vejen og{" "}
          <b>er kommet tilbage til det som virkelig betyder noget</b>.
        </p>

        <h3 className="attention">
          Min mission er, at give dig den mulighed - men hvor du slipper for de
          omveje jeg selv har gået!
        </h3>

        <h3 className="attention">
          Livet foregår ikke i hovedet, men i rummet lige nu og her, blandt de
          mennesker vi elsker.
        </h3>

        <p>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            href="terapi#priser"
          >
            Få hjælp nu
          </Button>
        </p>

        <br />

        <p>
          Jeg arbejder ud fra en{" "}
          <b>følelses- og oplevelsesorienteret tilgang</b>, med fokus på det liv
          du lever lige nu! Det sikrer at du hver dag{" "}
          <b>får mulighed for at flytte dig mod det du længes efter</b>. Det der
          virkelig betyder noget er dine handlinger, og det du retter
          opmærksomheden mod. For det er det der <b>former dine oplevelser</b>.
        </p>
        <h3 className="attention">
          Det materiale dine erindringer er lavet af!
        </h3>
        <p>
          Det er muligt at{" "}
          <b>finde kærligheden til både dig selv og dit barn</b>. Det er muligt
          at også de <b>svære ting kan føles lettere</b>. Dit liv behøver{" "}
          <b>ikke stå stand by</b> imens. Du kan leve det liv du længes efter
          nu!
        </p>
        <h3 className="attention">Er du klar til at tage styringen tilbage?</h3>

        <p>
          Er du i tvivl om hvorvidt et terapeutisk forløb hos mig er det rette
          for dig, så <b>kontakt mig for en gratis 30 minutters samtale</b> hvor
          vi kort kan vende din situation og fornemme kemien.
        </p>
        <p>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ContactMailIcon />}
            href="/kontakt"
          >
            Kontakt mig
          </Button>
        </p>

        <br />

        <p>
          En 1:1 terapeutisk session hos mig foregår som{" "}
          <b>videosamtale over en sikker, krypteret forbindelse</b> og er af 60
          minutters varighed.
        </p>
        <p>
          Efter vi har sagt farvel i vores 1:1 session modtager du en{" "}
          <b>skriftlig opsamling på det vi har arbejdet med</b>, samt eventuelle
          aftaler vi har ift. hvad du skal fokusere på inden næste samtale. Det
          betyder at du kan give slip under vores samtale og slappe af -
          velvidende at vigtige pointer får du tilsendt efterfølgende.
        </p>
        <p>
          Du får en <b>direkte privat linie til mig</b>, som er krypteret, hvor
          du kan kontakte mig ubegrænset på skrift mellem vores sessioner. Jeg
          svarer dig indenfor 24 timer.{" "}
          <b>Du har en livline til mig hele tiden!</b>
        </p>
        <p>
          Jeg giver dig løbende <b>skræddersyet materiale</b>, som du kan
          arbejde med mellem sessionerne for at <b>øge dit udbytte</b> af vores
          arbejde. Undervejs opsamler vi på hjemmearbejdet og{" "}
          <b>tilpasser det materiale du arbejder med</b>.
        </p>
        <p>
          Typisk vil 1:1 sessionerne ligge med{" "}
          <b>14 dages til en måneds mellemrum</b>. Det giver rum til, at du kan
          arbejde med øvelserne, og samtidig er du i bevægelse gennem de
          terapeutiske sessioner.
        </p>
        <p>
          Den kontakt vi har under dit terapeutiske arbejde{" "}
          <b>er helt igennem unik</b>! Den giver dig en{" "}
          <b>intensiv oplevelse</b> og sikrer, at du får det{" "}
          <b>fulde udbytte af det arbejde vi laver i vores 1:1 sessioner</b>.
        </p>
        <h3 className="attention">
          Udvikling kommer kun hvis de erkendelser du får i terapien, fører til
          handlinger ude i livet.
        </h3>
        <p>
          Derfor får du en <b>personlig og fortrolig støtte</b> igennem hele
          vores arbejde sammen. <b>Du får en coach som er der</b>, som støtter
          og hepper på dig. Og{" "}
          <b>
            du får en psykolog, som giver dig det rum - de terapeutiske
            oplevelser
          </b>{" "}
          du har behov for, så du med tiden kan slippe det der tynger dig.{" "}
          <b>Det giver dig ægte udvikling, som er kommet for at blive!</b>
        </p>
        {false && (
          <>
            <p>
              Typisk vil 1:1 sessionerne ligge med{" "}
              <b>14 dages til en måneds mellemrum</b>. Det giver rum til, at du
              kan arbejde med øvelserne, og samtidig er <b>du i bevægelse</b>{" "}
              gennem de terapeutiske sessioner.
            </p>
            <p>
              Den kontakt vi har under dit terapeutiske arbejde{" "}
              <b>er helt igennem unik</b>! Den giver dig en{" "}
              <b>intensiv oplevelse</b> og sikrer, at du får det{" "}
              <b>fulde udbytte af det arbejde vi laver i vores 1:1 sessioner</b>
              .
            </p>

            <h3>Med hver session får du</h3>
            <p>
              <b>60 minutters videosamtale:</b> en 1:1 terapeutisk session hos
              mig foregår som{" "}
              <b>videosamtale over en sikker, krypteret forbindelse</b>.
            </p>
            <p>
              <b>Skriftlig sparring og støtte mellem sessioner:</b> du får en
              direkte linie til mig, som er krypteret og privat, hvor du kan
              kontakte mig ubegrænset mellem vores sessioner, og jeg svarer
              indenfor 24 timer. Du har en livline til mig hele tiden!
            </p>
            <p>
              <b>Skræddersyet materiale:</b> jeg giver dig løbende skræddersyet
              materiale i form af fx meditationer eller psykologiske øvelser,
              som du kan arbejde med mellem sessionerne for at øge dit udbytte
              af vores arbejde. Undervejs opsamler vi på hjemmearbejdet og{" "}
              <b>tilpasser det materiale du arbejder med</b>.
            </p>
            <p>
              <b>Skriftlig opsamling på session:</b> efter vores videosessioner
              laver jeg en opsamling på det vi har arbejdet med, samt eventuelle
              aftaler vi har ift. hvad du skal fokusere på inden næste samtale.
              Det betyder at du kan give slip under vores samtale og slappe af -
              velvidende at vigtige pointer får du tilsendt efterfølgende.
            </p>

            <h3>
              Udvikling kommer kun hvis de erkendelser du får i terapien, fører
              til handlinger ude i livet.
            </h3>
            <p>
              Derfor får du en <b>personlig og fortrolig støtte</b> igennem hele
              vores arbejde sammen. <b>Du får en coach som er der</b>, som
              støtter og hepper på dig. Og{" "}
              <b>
                du får en psykolog, som giver dig det rum - de terapeutiske
                oplevelser
              </b>{" "}
              du har behov for, så du med tiden kan slippe det der tynger dig.{" "}
              <b>Det giver dig ægte udvikling, som er kommet for at blive!</b>
            </p>
          </>
        )}
        <br />
        <h2 id="priser">Priser</h2>
        <p>
          Du kan købe sessioner hos mig enten som enkelte sessioner eller som
          forudbetalt klippekort.
        </p>
        <h3 className="mix">
          <span className="attention">
            1 session inklusiv skriftlig støtte og kontakt mellem sessioner:{" "}
          </span>
          1.495 kr.
        </h3>
        <h3 className="mix">
          <span className="attention">
            10 sessioner inklusiv skriftlig støtte og kontakt mellem sessioner:{" "}
          </span>
          13.400 kr.
        </h3>
        <br />
        <p>
          <b>Betalingsplan kan aftales.</b>
        </p>
        <p>
          <b>
            Jeg gør opmærksom på, at jeg ikke har ydernummer og du derfor ikke
            kan bruge din henvisning fra lægen hos mig. Jeg har ikke samarbejde
            med private sundhedsforsikringer, som Sygeforsikringen Danmark eller
            andre.
          </b>
        </p>
        <p>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ContactMailIcon />}
            href="/kontakt"
          >
            Kontakt mig
          </Button>
        </p>
      </SectionLayout>
    </Layout>
  );
};

export default Terapi;
