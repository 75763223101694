import React from "react";

import Layout from "../../components/layout";
import SectionLayout from "../../components/sectionLayout";
import "../../styles/content.scss";
import { Helmet } from "react-helmet";

const Handelsbetingelser = () => (
  <Layout title="Handelsbetingelser og abonnementsbetingelser">
    <Helmet>
      <title>Handelsbetingelser</title>
      <meta name="robots" content="nosnippet" />
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <SectionLayout>
      <h3>Generelle oplysninger</h3>
      <p>
        Barselspsykologen
        <br />
        Fladkærvej 22
        <br />
        9370 Hals
        <br />
        CVR nr.: 40972625
        <br />
        Telefon: 26743900
        <br />
        Email: kontakt@barselspsykologen.dk
      </p>

      <h3>Priser</h3>
      <p>
        Hos Barselspsykologen er alle priserne i danske kroner og angivet inkl.
        moms og afgifter.
      </p>
      <p>
        Undtagelse til priser er psykologydelser med personlig konsultation, som
        er momsfritaget, og angivet i danske kroner inkl. afgifter men uden
        moms.
      </p>
      <p>
        Vi forbeholder os ret til fra dag til dag at ændre i priserne uden
        forudgående samtykke. Der tages forbehold for udsolgte varer.
      </p>
      <p>
        Priser for eksisterende abonemmenter kan ændres med 3 måneders varsel.
      </p>

      <h3>Betaling</h3>
      <p>
        Barselspsykologen modtager betaling med VISA-Dankort, VISA, VISA
        Electron og Mastercard. Alle beløb er i DKK. Danske kroner og incl.
        moms. En undtagelse er psykologydelser med personlig konsultation, som
        er momsfritaget, og derfor uden moms. Der tages forbehold for prisfejl
        og udsolgte/udgåede varer.
      </p>
      <p>
        For abonnementer gælder at abonnementsprisen, som oplyst på
        produktsiden, automatisk bliver trukket for hver abonnementsperiode.
        Kvittering for trækket tilsendes per email.
      </p>
      <p>
        Ønsker du at ændre det kort abonnementet trækkes på gøres dette ved at
        klikke på det link, der står i hver faktura.
      </p>

      <h3>Levering</h3>
      <p>
        Produkterne bliver leveret digitalt via e-mail, digital online platform
        eller som personlig konsultation.
      </p>
      <p>
        Du modtager en email med bekræftelse på din abonnementstilmelding eller
        produktkøb efter indgåelse af abonnementet eller køb af produkt.
      </p>
      <p>
        Levering sker som oftest i løbet af få minutter, men i helt særlige
        tilfælde kan der gå op til 5 hverdage.
      </p>
      <p>
        Tid for konsultationer aftales direkte med Iben Præstholm Vutborg på
        mail iben@barselspsykologen.dk
      </p>

      <h3>Refusion</h3>
      <p>
        Hvis der er tale om refusion, bedes du medsende bankoplysninger i form
        af regnr og kontonr, så det aftalte beløb kan overføres. Disse
        oplysninger kan uden risiko oplyses pr. mail eller anden elektronisk
        form, da det ikke er følsomme oplysninger og kun vil blive anvendt til
        vores opfyldelse af refusionen.
      </p>

      <h3>Fortrydelsesret</h3>
      <p>
        Der er 14 dages returret. Hvis du fortryder dit køb inden 14 dage, så
        kontakt mig på kontakt@barselspsykologen.dk og du vil få alle pengene
        tilbage.
      </p>
      <p>
        Undtagelser til fortrydelsesretten er allerede afholdte konsultationer.
      </p>

      <h3>Ophør og opsigelse</h3>
      <p>
        Du kan når som helst opsige dit abonnement ved at skrive til
        kontakt@barselspsykologen.dk, eller ved at logge ind på din konto og
        selv stoppe abonnementet. Opsigelsen er øjeblikkelig, og efter
        opsigelsen vil der ikke blive faktureret eller trukket yderligere.
        Allerede udsendte fakturaer skal dog stadig betales.
      </p>
      <p>
        Såfremt en Bruger ikke foretager betaling af sit abonnement eller
        produkt, for eksempel ved manglende dækning på en betalingskonto,
        forbeholder Barselspsykologen retten til at lukke adgangen og opsige
        abonnementet, umiddelbart efter manglende betaling er registreret.
      </p>
      <p>
        Barselspsykologen forbeholder sig retten til at opsige et abonnement med
        3 måneders varsel.
      </p>
      <p>
        Barselspsykologen forbeholder sig retten til at opsige en Brugers
        abonnement uden varsel såfremt der sker en væsentlig overtrædelse af
        betingelser eller hvis der udvises upassende eller hadefuld adfærd
        f.eks. på fora og under webinar. Det er udelukkende op til
        Barselspsykologen at vurdere hvert tilfælde.
      </p>
      <p>
        Barselspsykologen forbeholder sig ret til at slette opslag eller
        kommentarer på fora, som ikke vurderes passende af Barselspsykologen og
        de til en hver tid opdateret grupperegler.
      </p>
      <p>
        Barselspsykologen forbeholder sig ret til at lukke adgangen til digitale
        produkter 3 år efter købsdato. Det er købers ansvar at downloade og
        gemme det digitale produkt indenfor 3 år efter købsdato.
      </p>

      <h3>Disclaimer</h3>
      <p>
        Barselspsykologens digitale produkter er ikke en erstatning for
        behandling af psykiske lidelser. Der stilles ikke diagnoser eller
        lignende personlige vurderinger.
      </p>

      <h3>Rettigheder</h3>
      <p>
        Alle rettigheder til workshops, kurser, events og digitale produkter
        tilhører Barselspsykologen. Ved køb opnås licens til at benytte det
        købte produkt som angivet under det pågældende produkt. Dit digitale
        produkt er personligt, og det er ikke tilladt at sælge, videresende,
        udlåne, give det købte væk eller på anden måde distribuere produktet,
        hverken privat eller kommercielt. Det gælder både for indholdet af vores
        online produkter, downloadede produkter, livehold, workshop og kurser.
      </p>

      <h3>Persondatapolitik</h3>
      <p>
        For at du kan indgå aftale med os og handle på vores hjemmeside via
        barselspsykologen.simplero.com, har vi brug for følgende oplysninger om
        dig:
      </p>
      <p>
        Navn
        <br />
        E-mailadresse
        <br />
        Oplysning om hvad du har købt
      </p>
      <p>
        Vi behandler dine personoplysninger med det formål, at kunne levere
        varen til dig, og for at kunne behandle henvendelser vedrørende dit køb.
        Behandlingen sker efter reglerne i vores{" "}
        <a href="/policy/persondatapolitik">
          <strong>persondatapolitik</strong>
        </a>{" "}
        for Barselspsykologen. Heri kan du læse mere om, hvordan dine
        oplysninger behandles, hvornår de slettes, og hvilke rettigheder du har
        som registreret.
      </p>
      <h3>Ændring af betingelser</h3>
      <p>
        Barselspsykologen kan til en hevr tid ændre og opdatere disse
        betingelser og oplysninger om rettigheder. Gældende betingelser vil
        altid være tilgængelig på barsekspsykologen.dk. Brug af
        barselspsykologen.simplero.com inklusiv adgang via Simplero APP eller
        barselspsykologen.dk efter en ændring af disse betingelser, betragtes
        som din accept af de ændrede betingelser.
      </p>
      <h3>Gyldighed</h3>
      <p>
        Disse betingelser er gyldige fra den 5. november 2023 og erstatter alle
        tidligere versioner.
      </p>
    </SectionLayout>
  </Layout>
);

export default Handelsbetingelser;
