import React, { useEffect } from "react";
import Layout from "../../components/layout";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";
import { ProductPage } from "./productPage";
import { ProductInfo } from "./useProducts";

export const productInfo: ProductInfo = {
  anchorTag: "1",
  title: "Blid fravænning af amning",
  longTitle: "Blid fravænning af amning",
  priceDescription: "Pris: 49 kr. inklusiv moms",
  description: (
    <>
      <p>Få guiden "Blid Fravænning af amning" direkte til din mail!</p>
      <p>
        Guiden består af 6 siders indhold fordelt over 8 konkrete tips og råd,
        som ruster dig til at fravænne dit barn fra amningen på en blid og
        nænsom måde.
      </p>
    </>
  ),
  saleUrl:
    "https://barselspsykologen.simplero.com/cart/207024-Blid-fravaenning-af-amning",
  pageUrl: "/guides/blid-fravaenning-af-amning",
};

const BlidFravaenningAfAmning: React.FC = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("Show page: Blid fravænning af amning");
  }, []);

  return (
    <Layout title={productInfo.title}>
      <Helmet>
        <meta
          name="description"
          content="Få en guide til blid fravænning af amning."
        />
        <meta
          name="keywords"
          content="blid fravænning af amning, online guides, psykolog, ammespecialist, Barselspsykologen"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://barselspsykologen.dk/guides/blid-fravaenning-af-amning"
        />
        <title>Blid fravænning af amning | Barselspsykologen</title>
      </Helmet>
      <ProductPage product={productInfo}>
        <p>
          Et ammestop kan være særligt svært, når amningen har været meget mere
          end blot mælk. Amning er for mange også en måde at trøste på og hjælpe
          dit barn til ro på - og hvad så når amningen skal udfases?
          <br />
          Denne guide hjælper dig med netop det!
        </p>
        <p>
          Du får helt nede-på-jorden råd og vejledning til et ammestop som
          beskytter jeres tilknytning. Mailen tager udgangspunkt i et ammestop
          med god tid, så både du og dit barn kan følge med.
        </p>
      </ProductPage>
    </Layout>
  );
};

export default BlidFravaenningAfAmning;
