import React, {useEffect} from "react"

import Layout from "../components/layout"
import { content } from "../content/product/amning";
import SectionLayout from "../components/sectionLayout";
import { Button } from '@mui/material';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import "../styles/content.scss";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";

const Amning: React.FC = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("Show page: amning");
  }, []);

  return (
    <Layout title={content.title}>
      <Helmet>
        <meta
          name="description"
          content="Få professionel hjælp til amning med ammespecialist og psykolog. Jeg tilbyder personlig vejledning og støtte, både online og som hjemmebesøg."
        />
        <meta
          name="keywords"
          content="ammehjælp, ammevejledning, ammespecialist, hjælp til amning, online ammevejledning, Barselspsykologen, nybagt mor, ammeproblemer, nordjylland"
        />
        <meta name="robots" content="index, follow"></meta>
        <link rel="canonical" href="https://barselspsykologen.dk/amning" />
        <title>Hjælp til Amning | Barselspsykologen</title>
      </Helmet>
      <SectionLayout>
        <p>
          Få hjælp til amning, som er mere end blot ammevejledning.{" "}
          <b>Fordi amning er mere end mælk!</b>
        </p>
        <h2>Er du nybagt mor og er amningen svær?</h2>
        <ul>
          <li>
            Har du <b>smerter og sår?</b> Og oplever du at uanset hvad du
            prøver, så er der <b>intet der virker?</b>
          </li>
          <li>
            Føler du dig <b>generelt i tvivl om amningen</b>, har du mange
            spørgsmål og usikkerheder, som du ikke føler du får ordentligt svar
            på?
          </li>
          <li>
            Måske <b>tager din baby ikke nok på</b> og du er bekymret for at
            supplere?
          </li>
          <li>
            Eller måske <b>supplerer</b> du allerede amningen med flaske og
            modermælkserstatning og er bange for et fuldt ammestop?
          </li>
          <li>
            Det kan være du er <b>stoppet med at amme</b>, men savner det og
            ønsker at <b>genetablere</b> amningen?
          </li>
          <li>
            Er dit barn <b>for-tidligt-født</b>, født med særlige behov eller er
            du mor til tvillinger/flerlinger og{" "}
            <b>ønsker du brændende at amme?</b>
          </li>
        </ul>
        <h2>Føler du dig frustreret, ked af det og kørt fast?</h2>
        <ul>
          <li>
            Har du fået mange og modsatrettede råd fra familie og fagfolk, og
            føler du dig <b>forvirret og handlingslammet?</b>
          </li>
          <li>
            Oplever du at amningen tager al opmærksomheden og overvejer du at
            stoppe, selvom du <b>brændende ønsker at amme?</b>
          </li>
          <li>
            Oplever du at amning og moderskabet er både{" "}
            <b>overvældende og svært</b>, og fyldt med flere følelser end du
            havde forestillet dig?
          </li>
        </ul>
        <h3 className="attention">Er det dig?</h3>
        <h3 className="attention">Så kan jeg hjælpe dig videre!</h3>
        <p>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            href="amning#priser"
          >
            Få hjælp nu
          </Button>
        </p>

        <br />

        <h3 className="attention">Men jeg slipper dig ikke der!</h3>

        <p>
          Du får mig også som <b>din helt personlige livline i en hel uge</b>{" "}
          efter vores første samtale.
        </p>
        <p>
          Vi har <b>skriftlig kontakt undervejs</b> sammen med en eller flere{" "}
          <b>telefonsamtaler fordelt over ugen</b> efter behov. Du kan skrive
          til mig hver dag og jeg vil svare dig samme dag. Ingen spørgsmål er
          for store og ingen for små. Det{" "}
          <b>allervigtigste er at du føler dig tryg</b> og holdt i hånden gennem
          hele forløbet.
        </p>
        <p>
          <b>Vi afslutter forløbet med en telefonsamtale</b> for at runde af og
          afklare den fremadrettede plan.
          <br />
          Du har efterfølgende mulighed for at forlænge med endnu en uges
          skriftlig/telefonisk kontakt.
        </p>

        <p>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            href="amning#priser"
          >
            Få hjælp nu
          </Button>
        </p>

        <br />

        <p>
          Forestil dig at have en fagperson lige ved hånden, som giver{" "}
          <b>plads til dig, din baby og jeres unikke situation</b>. En fagperson
          som tilpasser hjælpen lige præcis jer, og som{" "}
          <b>svarer på alle spørgsmål</b> som måtte presse sig på - både ift.{" "}
          <b>amning, babys behov, søvn og tilknytning</b>.
        </p>
        <h3 className="attention">En personlig LIVLINE lige ved hånden!</h3>
        <p>
          Som <b>uddannet ammespecialist/IBCLC og psykolog</b> er jeg ved din
          side, så vi trygt og roligt kan finde frem til løsninger, der passer
          til jeres situation. <b>Dine ønsker og behov er i centrum!</b>
        </p>
        <p>
          Når du booker en konsultation til ammehjælp hos mig får du en{" "}
          <b>90 minutters konsultation</b>, hvor vi kan snakke om det forløb I
          har haft indtil nu, hvilke følelser der fylder for dig og hvad{" "}
          <b>du drømmer om</b> fremadrettet.
        </p>
        <p>
          <b>Amning er mere end mælk</b>. Hos mig er der plads til alle
          følelserne forbundet med amning. Både{" "}
          <b>kærligheden, frustrationen og sorgen</b>.
        </p>
        <p>
          Undervejs i konsultationen vil du få rig mulighed for at få{" "}
          <b>svar på alle spørgsmål</b> som presser sig på. Du kommer herfra med
          både <b>vished og ro i maven</b>.
        </p>
        <p>
          <b>
            Jeg hjælper dig videre med helt konkrete værktøjer, viden og støtte.
          </b>
        </p>
        <p>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            href="amning#priser"
          >
            Få hjælp nu
          </Button>
        </p>

        <br />

        <h3>Book din konsultation nu!</h3>
        <p>
          Du kan booke din konsultation, ved at kontakte mig helt uforpligtende
          via kontaktformularen her på siden.{" "}
          <b>Du hører fra mig indenfor 24 timer</b>.
        </p>
        <p>
          Du får mulighed for først at fortælle mig lidt om{" "}
          <b>jeres baggrundshistorie</b> og den problematik I oplever.
        </p>
        <p>
          Hvis jeg vurderer jeg er den som kan hjælpe jer allerbedst videre,
          finder vi en tid til konsultation. Du har mulighed for at vælge en{" "}
          <b>hjemmekonsultation eller en online konsultation</b> over en sikker
          forbindelse.
        </p>

        <br />

        <h2 id="priser">
          Pris for ammehjælp som online konsultation: 3100 kr. inklusiv moms
        </h2>
        <p>
          <b>Inkluderet i prisen er:</b>
        </p>
        <ul>
          <li>90 minutters videokonsultation over krypteret forbindelse.</li>
          <li>En hel uges skriftlig kontakt.</li>
          <li>Svar på ALLE dine spørgsmål.</li>
          <li>
            Relevant skriftlig eller video materiale sendt direkte til dig.
          </li>
          <li>Telefonisk kontakt ved behov.</li>
          <li>
            Dag-til-dag vejledning – en personlig livline lige ved hånden!
          </li>
          <li>
            Støtte og hjælp til den følelesmæssige belastning det er at have
            problemer med amningen.
          </li>
          <li>En afsluttede telefonisk samtale.</li>
        </ul>
        <p>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ContactMailIcon />}
            href="/kontakt"
          >
            Kontakt mig
          </Button>
        </p>

        <h2>Pris for ammehjælp som hjemmebesøg: 3600 kr. inklusiv moms</h2>
        <p>
          <b>Inkluderet i prisen er:</b>
        </p>
        <ul>
          <li>90 minutters konsultation i jeres hjem.</li>
          <li>En hel uges skriftlig kontakt.</li>
          <li>Svar på ALLE dine spørgsmål.</li>
          <li>
            Relevant skriftlig eller video materiale sendt direkte til dig.
          </li>
          <li>Telefonisk kontakt ved behov.</li>
          <li>
            Dag-til-dag vejledning - en personlig livline lige ved hånden!
          </li>
          <li>
            Støtte og hjælp til den følelesmæssige belastning det er at have
            problemer med amningen.
          </li>
          <li>En afsluttede telefonisk samtale.</li>
          <li>
            25 km kørsel fra min adresse. Yderligere km afregnes efter statens
            gældende takster.
          </li>
        </ul>
        <p>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ContactMailIcon />}
            href="/kontakt"
          >
            Kontakt mig
          </Button>
        </p>

        <h3>
          Pris for forlængelse af skriftlig/telefonisk kontakt (1 uge): 1000 kr.
          inklusiv moms
        </h3>
      </SectionLayout>
    </Layout>
  );
}

export default Amning;
