import React from "react"

import Layout from "../../components/layout"
import SectionLayout from "../../components/sectionLayout";
import styles from "./video.module.scss";

const TipsForBabyCrying = () => (
  <Layout title="Tips & tricks til håndtering af gråd">
    <SectionLayout>
      <video className={styles.playerWrapper} controls>
        <source src="./media/video-2.mov" />
      </video>
    </SectionLayout>
  </Layout>
)

export default TipsForBabyCrying  