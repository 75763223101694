import { productInfo as productBlidFravaenningAfAmning } from "./blid-fravaenning-af-amning";
import { productInfo as productForstaaDinBaby } from "./forstaa-din-baby";
import { productInfo as productFaaEnDejligereJul } from "./faa-en-dejligere-jul";

export interface ProductInfo {
  anchorTag: string;
  title: string;
  longTitle: string;
  priceDescription: string;
  description: React.ReactNode;
  saleUrl: string;
  pageUrl: string;
  previewLabel?: string;
  previewUrl?: string;
}

export const useProducts = (): ProductInfo[] => {
  return [
    productFaaEnDejligereJul,
    {
      anchorTag: "3",
      title: "Undgå stress og overvældelse i barslen",
      longTitle:
        "Undgå stress og overvældelse i barslen",
      priceDescription: "Pris: 0 kr. når du tilmelder dig mit nyhedsbrev",
      description: (
        <>
          <p>
            Undgå stress og overvældelse i barslen, så du og dit barn kan få den
            bedste start på tilværelsen sammen.
          </p>
        </>
      ),
      saleUrl: "/nyhedsbrev#signup",
      pageUrl: "/nyhedsbrev",
    },
    productBlidFravaenningAfAmning,
    productForstaaDinBaby,
  ];
};
