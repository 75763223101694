import React from "react";
import Layout from "../../components/layout";
import SectionLayout from "../../components/sectionLayout";
import styles from "./newsletter.module.scss";
import { RouteComponentProps, useLocation } from "react-router-dom";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import Iben1Preview from "../../images/mindfulmor_iben.jpeg";
import Button from "@mui/material/Button";
import FavoriteIcon from "@mui/icons-material/Favorite";

const NewsletterConfirm: React.FC<RouteComponentProps> = (props) => {
  React.useEffect(() => {
    amplitude.getInstance().logEvent("Show page: newsletter_confirm");
  }, []);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  React.useEffect(() => {
    const signupId = query.get("id");

    if (signupId != null && signupId.length > 30) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: '"' + encodeURI(signupId) + '"',
      };

      fetch("newsletter/confirm", requestOptions).then((d) => {
        // do nothing
      });
    }
  }, [query]);

  return (
    <Layout title="Nyhedsbrev" altClassName={styles.newsletter}>
      <Helmet>
        <title>Bekræft nyhedsbrev</title>
        <meta name="robots" content="nosnippet" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <SectionLayout altClassName={styles.signature}>
        <img src={Iben1Preview} />
        <motion.div
          initial={{ x: 100 }}
          whileInView={{ x: 0 }}
          transition={{
            type: "spring",
            bounce: 0.4,
            duration: 0.8,
          }}
        >
          <p>
            <strong>Tusind tak, fordi du bekræftede din tilmelding 🥳</strong>
          </p>
        </motion.div>
        <p>
          Om lidt lander dit minikursus i din e-mail 📬 Jeg ser så meget frem
          til, at være en hjælp og støtte i dit moderskab{" "}
          <FavoriteIcon color="primary" fontSize="medium" />
        </p>
        <p>
          <br />
          Mens du venter på dit minikursus, kan du læse lidt om mig{" "}
          <a href="/om-mig">her</a>. Jeg tilbyder også{" "}
          <a href="/terapi">terapeutisk forløb</a> og{" "}
          <a href="/amning">ammehjælp</a>
        </p>
        <p>
          <br />
          Jeg har også en lille samling online guides, hvor du får hjælp med det
          samme. Dem finder du lige herunder 👇
          <br />
          <Button href="/guides">Online guides</Button>
        </p>
        <p>
          <br />
          <br />
          <br />
          Har du spørgsmål eller andet du vil dele med mig, så er du altid
          velkommen til at kontakte mig på{" "}
          <span>iben@barselspsykologen.dk</span>
          <br />
          <FavoriteIcon color="primary" fontSize="medium" /> Jeg ser frem til at
          høre fra dig 🫶
        </p>
      </SectionLayout>

      <SectionLayout>
        <div className={styles.guides}></div>
      </SectionLayout>
    </Layout>
  );
};

export default NewsletterConfirm;
