import { createTheme, adaptV4Theme } from '@mui/material/styles';

export const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: "#DA8C9A", // or whatever colors you need
    },
    secondary: {
      main: "#F2BDC6", // or whatever colors you need
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        padding: '0 !important',
      }
    },
    MuiGrid: {
      root: {
        padding: '0 0 30px 0'
      }
    }

  }
}));
