import React from "react"

export const content = {
  title: 'Hjælp til Amning',
  offerTitle: 'Du får',
  priceTitle: 'Priser',
  description: (<>
    <p>Er du i tvivl i dit forælderskab? Føler du dig <b>splittet, udmattet og usikker?</b></p>
    <p>Som nybagt familie er det let at <b>blive overvældet af anbefalinger</b>, meninger og velmente råd fra sin omgangskreds og diverse fagfolk. Alle har en mening om livet med et lille barn og hvad der er bedst.</p>
    <p>Har du brug for hjælp til at finde frem til hvordan du kan <b>imødekomme dit barns behov</b> og <b>skabe trivsel</b> for dig selv og din familie?</p>
    <p>Familievejledning hos mig har fokus på dig, din familie og jeres specifikke situation. Problemstillingen kan være konkret eller mere diffus. Fælles er at <b>trivslen er udfordret</b> og der er brug for at få vendt situationen, så tilfredshed og tryghed kan få lov at udfolde sig i jeres familieliv.</p>
    <p>Du/I kan stille de spørgsmål der presser sig på <b>i et trygt rum</b>, hvor der er plads til at udforske udfordringer eller problemstillinger nysgerrigt.</p>
    <p>Om det er udfordringer omkring fx søvn, uro og gråd, tilbagevende konflikter i parforholdet eller i den nærmeste familie, tristhed i forælderskabet, problemer med at aflæse barnets signaler eller søskenderelationer, så kan forældrevejledning med mig være med til at skabe klarhed og tryghed, så <strong>du/I kan komme styrkede videre</strong>.</p>
    <p>Konsultationer til familievejledning er á 60 minutters varighed og kan bookes enten som hjemmebesøg eller videokonsultation over krypteret forbindelse.</p>
  </>),
  offer: [
    'Hjælp til at forstå dit barns behov og skabe trivsel i familien',
    'Vejledning som tager udgangspunkt i dig og din families individuelle situation',
    'Et trygt rum hvor du kan stille alle spørgsmål og få svar',
    'Hjælp direkte i dit hjem som hjemmekonsultation eller over krypteret forbindelse',
  ],
  prices: [
    {
      title: 'Hjemmekonsultation',
      description: 'af 1 times varighed, 950 kr.',
      additionalTitle: 'Efterfølgende 30 minutter, 450 kr.',
    },
    {
      title: 'Videokonsultation',
      description: 'af 1 times varighed, 795 kr.',
      additionalTitle: 'Efterfølgende 30 minutter, 350 kr.',
    }
  ]
};
