import React, {useEffect} from "react";
import Layout from "../../components/layout";
import SectionLayout from "../../components/sectionLayout";
import SectionGroup from "../../components/sectionGroup";
import styles from "./newsletter.module.scss";
import amplitude from "amplitude-js";
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FreebieExample from '../../images/freebie-stress.png'
import FreebiePreview from '../../images/freebie-stress-preview.jpeg'
import { Helmet } from "react-helmet";


const NewsletterV2: React.FC = () => {
  const [email, setEmail] = React.useState<string>('');
  const [firstname, setFirstname] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);
  
  useEffect(() => {
    amplitude.getInstance().logEvent("Show page: newsletterV2");
  }, []);

  const signup = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: "{\"email\":\"" + encodeURI(email) + "\", \"firstname\":\"" + firstname + "\"}"
    };

    fetch('newsletter/signup', requestOptions)
      .then(d => {
        setSuccess(true);
      });
      
  }

  return (
    <Layout title="Gratis minikursus!">
      <Helmet>
        <meta
          name="description"
          content="Jeg tilbyder et gratis minikursus om stress og overvældelse som ny forælder. Få redskaber til at håndtere efterfødselstiden med tryghed og tro på dig selv."
        />
        <meta
          name="keywords"
          content="gratis minikursus, stress som ny forælder, overvældelse efter fødsel, forældreskab, barsel, psykolog"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://barselspsykologen.dk/nyhedsbrev" />
        <title>Gratis minikursus! | Barselspsykologen</title>
      </Helmet>
      <SectionGroup altClassName={styles.newsletter}>
        <SectionLayout>
          <h2 className="attention">
            Sådan undgår du stress og overvældelse som ny forælder
          </h2>
          <p>
            Det er en kæmpe omvæltning at blive nogens forælder. Og ligesom du
            garanteret forbereder dig på fødslen, skal du også forberede dig på
            det mentale skifte, det er at blive forælder.
          </p>
          <p>
            Stress og overvældelse i efterfødselstiden kan let sætte sig i os
            som mødre, og udvikle sig til depression eller angst over tid.
          </p>
          <p>
            Det stjæler ikke kun dyrebar tid med din baby - det kan også påvirke
            dit barn og jeres tilknytning 💔
          </p>
        </SectionLayout>
        <SectionLayout altClassName={styles.introduction}>
          <h2>Få øjeblikkelig adgang til minikurset</h2>
          <p>
            Det koster kun din e-mail og dit JATAK til at modtage mit
            nyhedsbrev!
          </p>
          <div className={styles.centered}>
            <Button
              variant="contained"
              color="primary"
              href="nyhedsbrev#signup"
              size="large"
            >
              JATAK!
            </Button>
          </div>
          <a href="nyhedsbrev#signup">
            <div className={styles.image}>
              <img src={FreebiePreview} width="100%" />
            </div>
          </a>
        </SectionLayout>
        <SectionLayout>
          <p>
            Det er enormt vigtigt at forberede sig mentalt på forældreskabet.
          </p>
          <p>
            Stress og overvældelse over lang tid påvirker vores opfattelse af
            verden, fordi vi tolker flere ting som fare (og dermed også vores
            barn og dets signaler).
          </p>
          <p>
            Det frarøver os dyrebare øjeblikke med vores barn og det sætter sig
            i os og vores relation 💔
          </p>
          <p>Det gode er at du kan forebygge det!</p>
          <p>
            Og det giver dette minikursus dig redskaberne til{" "}
            <FavoriteIcon color="primary" />
          </p>
          <p className="attention">
            Minikurset består af en introduktionsmail og 4 lektioner, som bliver
            sendt direkte til din indbakke hver morgen over fire dage.
          </p>
          <p>
            Tag lektionerne i dit eget tempo, og mærk hvordan du går ind i
            forældreskabet med tryghed og troen på, at du gør det helt rigtige.
          </p>
          <div className={styles.image}>
            <img src={FreebieExample} width="100%" />
          </div>
        </SectionLayout>
        <SectionLayout altClassName={styles.product}>
          <h3 className="attention">I minikurset lærer du:</h3>
          <p>
            <ul>
              <li>
                Hvordan din NYE hjerne fungerer og hvorfor du måske allerede i
                graviditeten oplever store følelsesmæssige udsving.
              </li>
              <li>
                Hvilke 3 konkrete råd du bør skrive ned og hænge op på
                opslagstavlen nu for at give dig selv og din baby en roligere
                barsel.
              </li>
              <li>
                Svaret, du ikke kan google dig til (men som alle mødre har brug
                for at høre!)
              </li>
              <li>
                Hvorfor din baby pludselig ændrer adfærd og hvad du kan gøre ved
                det.
              </li>
              <li>
                Hvordan du bedst håndterer din babys gråd (både for din egen og
                din babys skyld!)
              </li>
              <li>3 psykologiske mekanismer bag panik-ammestop og hvordan du håndterer dem.</li>
            </ul>
          </p>
        </SectionLayout>
        <SectionLayout altClassName={styles.signup}>
          <h3 className="attention">JATAK! Giv mig en tryggere barsel nu!</h3>
          <form autoComplete="off" onSubmit={signup} id="signup">
            <TextField
              sx={{ width: "300px" }}
              id="standard-basic"
              label="Fornavn"
              variant="standard"
              required
              onChange={(evt) => setFirstname(evt.target.value)}
            />
            <TextField
              sx={{ width: "300px" }}
              id="standard-basic"
              label="Email"
              variant="standard"
              required
              onChange={(evt) => setEmail(evt.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
            >
              Tilmeld
            </Button>
            {success && (
              <div className={styles.success}>
                <CheckIcon fontSize="large" />
                Tak, du er der næsten! Bekræft i mail om lidt
              </div>
            )}
          </form>
          <br />
          <p>
            Når du skriver dig op, giver du samtidigt samtykke til at modtage
            mit nyhedsbrev. Du kan til enhver tid opsige din tilmelding ved at
            klikke på “Afmeld” i bunden af mailen, eller sende en e-mail til
            kontakt@barselspsykologen.dk.
          </p>
          <p>
            Jeg videregiver ikke data (e-mail og fornavn) til tredjepart (dog
            undtaget partnere, som jeg bruger til afsending af nyheds e-mails).
          </p>
        </SectionLayout>
      </SectionGroup>
    </Layout>
  );
}

export default NewsletterV2
