import React from "react";

import styles from "./layout.module.scss";
import "../styles/shared.scss";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { theme } from "./theme";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import amplitude from "amplitude-js";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

import { menu } from "../content/menu";
import { NavLink } from "react-router-dom";
import { Divider } from "@mui/material";

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const ITEM_HEIGHT = 48;

interface Props {
  children: React.ReactNode;
  title?: String;
  subTitle?: String;
  altClassName?: string;
}

const Layout: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [showImportantInfo, setShowImportantInfo] =
    React.useState<boolean>(false);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const closeImportantInfo = () => {
    setShowImportantInfo(false);
  };

  const handleAcceptCookie = () => {
    amplitude.getInstance().init("60e969e7accc18e1686253012efbb7a3");
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (
      window.location.hostname !== "localhost" &&
      getCookieConsentValue() === "true"
    ) {
      handleAcceptCookie();
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={`${styles.root} ${props.altClassName ?? ""}`}>
          {showImportantInfo && (
            <div id="" className={styles.importantInfo}>
              <div className={styles.closeImportantInfo}>
                <IconButton
                  color="secondary"
                  aria-label="close"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={closeImportantInfo}
                >
                  <HighlightOffIcon fontSize="large" />
                </IconButton>
              </div>
              Pt. lukket for konsultationer grundet barsel.
              <br />
              Send mig en besked via{" "}
              <Link color="primary" href="/kontakt">
                kontaktformularen
              </Link>
              , hvis du ønsker en plads på venteliste.
            </div>
          )}
          <div className={styles.floatingMenu}>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              href="/kontakt"
            >
              Kontakt mig
            </Button>
            <div className={styles.circleWrapper}>
              <IconButton
                color="secondary"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={openMenu}
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          </div>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={closeMenu}
            PaperProps={{
              style: {
                width: "300px",
              },
            }}
          >
            {menu.menu.items.map((value, index) => (
              <>
                <MenuItem key={index}>
                  <NavLink to={value.link}>{value.title}</NavLink>
                </MenuItem>
                {value.splitAfter && <Divider />}
              </>
            ))}
          </Menu>

          <div className={`${styles.content} ${props.altClassName}`}>
            {props.title && (
              <div className={styles.title}>
                <Container>
                  <div className={styles.wrapper}>
                    <a href="/">
                      <div className={styles.image}></div>
                    </a>
                    <div className={styles.text}>
                      <a href="/">
                        <h2>barselspsykologen</h2>
                      </a>
                      <h1>{props.title}</h1>
                      {props.subTitle && <h3>- {props.subTitle}</h3>}
                    </div>
                  </div>
                </Container>
              </div>
            )}
            {props.children}
          </div>
          <footer>
            <div className={styles.info}>
              <div className={styles.social}>
                <FacebookIcon fontSize="large" />
                <InstagramIcon fontSize="large" />
                <div className={styles.title}>barselspsykologen.dk</div>
              </div>
              <div className={styles.item}>
                <div>
                  Tlf.: <a href="tel:26 74 39 00">2674 3900</a>
                </div>
                <div>
                  <a href="mailto:kontakt@barselspsykologen.dk">
                    <strong>kontakt@barselspsykologen.dk</strong>
                  </a>
                </div>
                <div>CVR-nr.: 40972625</div>
              </div>
              <div className={styles.item}>
                <div>Aalborg og Nordjylland</div>
                <div>Fleksible åbningstider</div>
                <div>Både hverdag og weekend</div>
              </div>
              <div className={styles.item}>
                <div>
                  <a href="/policy/handelsbetingelser">
                    <strong>Handelsbetingelser</strong>
                  </a>
                </div>
                <div>
                  <a href="/policy/persondatapolitik">
                    <strong>Persondatapolitik</strong>
                  </a>
                </div>
                <div>
                  <a href="/policy/cookies">
                    <strong>Cookies</strong>
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </ThemeProvider>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Accepter"
        declineButtonText="Afvis"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          backgroundColor: "#FFD04C",
          fontSize: "20px",
          color: "#2B373B",
        }}
        declineButtonStyle={{
          backgroundColor: "#D2D2CD",
          fontSize: "20px",
          color: "#2b373b88",
        }}
        expires={30}
        cookieName="ConsentToCookies"
        onAccept={handleAcceptCookie}
      >
        <div className={styles.cookieDetails}>
          <h4>
            Jeg bruger cookies til at indsamle statistik over brugen af
            hjemmesiden
          </h4>
          Hvis du klikker på knappen 'Accepter', godkender du, at der sættes
          cookies til brug for statistik. Hvis du klikker på knappen 'Afvis',
          sættes der ikke cookies til statistik – der benyttes dog en cookie for
          at huske dit valg.
          <br />
          <a href="/policy/cookies">Læs mere</a>
        </div>
      </CookieConsent>
    </>
  );
};
export default Layout;
