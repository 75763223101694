import React from "react"

export const kontakt = {
  title: 'Kontakt mig',
  description: (
    <>
      <p>
        Send mig en besked via kontaktformularen herunder.  
      </p>
      <p>
        Du kan forvente svar indenfor 24 timer.
      </p>
      <p>
        
      </p>
    </>

  )
};
