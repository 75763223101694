import React from "react"
import PropTypes from "prop-types"
import styles from "./sectionLayout.module.scss"
import { Container } from '@mui/material';

interface Props {
    children: any;
    altClassName?: string;
}

const SectionLayout: React.FC<Props> = props => {
  return (
    <div className={`${styles.sectionLayout} ${props.altClassName ?? ''}`} >
      <Container>
        {props.children}
      </Container>
      </div>
  )
}

export default SectionLayout
