import React, { useEffect } from "react";
import Layout from "../../components/layout";
import SectionLayout from "../../components/sectionLayout";
import SectionGroup from "../../components/sectionGroup";
import styles from "./newsletter.module.scss";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { RouteComponentProps, useLocation } from "react-router-dom";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";

const Unsubscribe: React.FC<RouteComponentProps> = (props) => {
  const [email, setEmail] = React.useState<string>("");
  const [success, setSuccess] = React.useState<boolean>(false);

  useEffect(() => {
    amplitude.getInstance().logEvent("Show page: unsubscribe");
  }, []);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const unsubscribe = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: '"' + encodeURI(email) + '"',
    };

    fetch("newsletter/unsubscribe", requestOptions).then((d) => {
      setSuccess(true);
    });
  };

  return (
    <Layout title="Afmeld nyhedsbrev!">
      <Helmet>
        <title>Afmeld nyhedsbrev</title>
        <meta name="robots" content="nosnippet" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <SectionGroup altClassName={styles.newsletter}>
        <SectionLayout>
          <h2 className="attention">Afmeld nyhedsbrev</h2>
          <p>
            Når du har afmeldt dig mit nyhedsbrev modtager du ikke flere mails
            fra mig.
            <br />
            Du kan til en hver tid tilmelde dig igen.
          </p>
        </SectionLayout>
        <SectionLayout altClassName={styles.signup}>
          <h3 className="attention">Bekræft din afmelding</h3>
          <form autoComplete="off" onSubmit={unsubscribe} id="unsubscribe">
            <TextField
              sx={{ width: "300px" }}
              id="standard-basic"
              label="Email"
              variant="standard"
              required
              defaultValue={query.get("email") || ""}
              onChange={(evt) => setEmail(evt.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
            >
              Afmeld
            </Button>
            {success && (
              <div className={styles.success}>
                <CheckIcon fontSize="large" />
                Du er nu afmeldt fra mit nyhedsbrev
              </div>
            )}
          </form>
        </SectionLayout>
      </SectionGroup>
    </Layout>
  );
};

export default Unsubscribe;
