import React, { useEffect } from "react";
import Layout from "../../components/layout";
import SectionLayout from "../../components/sectionLayout";
import {Button, Checkbox, Divider, FormControlLabel,} from "@mui/material";
import styles from "./buy.module.scss";
import TextField from '@mui/material/TextField';
import 'moment/locale/da';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import amplitude from 'amplitude-js';

interface Module {
  id: string;
  label: string;
  selected: boolean;
}

const modulesInitial: (Module[]) = [
  {
    id: 'm1_selvomsorg',
    label: 'Modul 1: Jeg har værdi! Din guide til selvomsorg: 800 kr.',
    selected: true
  },
  {
    id: 'm2_foelelser',
    label: 'Modul 2: Overblik over dine følelser og behov: 800 kr.',
    selected: true
  },
  {
    id: 'm3_graenser',
    label: 'Modul 3: Lær dine grænser at kende: 800 kr.',
    selected: true
  },
  {
    id: 'm4_kontrol',
    label: 'Modul 4: Tæm din indre kontrolfreak!: 800 kr.',
    selected: true
  },
  {
    id: 'm5_tankemylder',
    label: 'Modul 5: Sluk for tankemylderet: 800 kr.',
    selected: true
  }
]

const allModulesText: string = "Hele forløbet inkl. 5 live Q&A's: 3800 kr."

const ModerskabIBalanceBuy: React.FC = () => {

  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [notes, setNotes] = React.useState<string>('');

  const [success, setSuccess] = React.useState<boolean>(false);
  const [failed, setFailed] = React.useState<boolean>(false);
  const [missingModules, setMissingModules] = React.useState<boolean>(false);

  const [modules, setModules] = React.useState<Module[]>(modulesInitial);
  const [total, setTotal] = React.useState<number>(3800);

  useEffect(() => {
    const numModules = modules.filter(m => m.selected).length;
    const total = numModules * 800;
    setTotal(total > 3800 ? 3800 : total);
  }, modules);

  const buy = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setFailed(false);
    setSuccess(false);
    setMissingModules(false);

    const selectedModules = modules.filter(m => m.selected)
    if( selectedModules.length === 0) {
      setMissingModules(true);
      return;
    }


    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          Name: encodeURI(name),
          Email: encodeURI(email),
          Phone: encodeURI(phone),
          notes: encodeURI(notes),
          modules: encodeURI(selectedModules.length === 5 ? allModulesText : selectedModules.map(m => m.label).join('#')),
          price: total
        })
    };

    fetch('buy', requestOptions)
      .then(d => {
        if (d.status !== 200) {
          amplitude.getInstance().logEvent("Buy failed: Moderskab I Balance");
          setFailed(true);
        } else {
          amplitude.getInstance().logEvent("Buy succeeded: Moderskab I Balance");
          setSuccess(true);
        }
      })
      .catch((e) => {
        amplitude.getInstance().logEvent("Buy failed: Moderskab I Balance");
        setFailed(true);
      });
  }

  useEffect(() => {
    amplitude.getInstance().logEvent("Show page: Buy Moderskab I Balance");
  }, []);


  const handleAllModulesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModules(modules.map(m => ({...m, selected: event.target.checked})));
  }

  const handleModuleSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newModules = modules.map(m => ({...m, selected: event.target.value === m.id ? event.target.checked : m.selected}))
    setModules(newModules);
  }

  return (
    <Layout title='Køb Moderskab i Balance'>
      <SectionLayout altClassName={styles.root}>
        <h2>Lukket for tilmelding</h2>
        {false && (<>
        <p>
          Udfyld formularen her og du vil modtage en faktura på din mail-addresse indenfor få dage.
        </p>
        <form autoComplete="off" onSubmit={buy}>
          <div>
            <TextField className={styles.small} id="standard-basic" label="Dit navn" variant="standard" required onChange={evt => setName(evt.target.value)} />
          </div>
          <div>
            <TextField className={styles.small} id="standard-basic" label="E-mail" variant="standard" required onChange={evt => setEmail(evt.target.value)} />
          </div>
          <div>
            <TextField id="standard-basic" multiline label="Evt. bemærkninger" variant="standard" fullWidth onChange={evt => setNotes(evt.target.value)} inputProps={{maxLength: 300}} />
          </div>
          <div className={styles.allModules}>
            <FormControlLabel
              label={allModulesText}
                control={
                  <Checkbox
                      checked={modules.filter(m => m.selected).length === modules.length}
                      onChange={handleAllModulesSelected}
                  />
                }
            />
          </div>
          <div className={styles.modules}>
            {modules.map(module => (
                    <FormControlLabel
                        key={module.id}
                        value={module.id}
                        label={module.label}
                        control={
                          <Checkbox
                              checked={module.selected}
                              onChange={handleModuleSelected}
                          />
                        }
                    />
                )
            )}
          </div>
          <div className={styles.total}>I alt: {total} kr. inkl. moms</div>
          <div className={styles.bookBtn}>
            <div>
              <Button type="submit" variant="contained" size="large" color="primary">
                Køb
              </Button>
            </div>
            {success && (<div className={styles.success}><CheckIcon fontSize="large" />Tak, du modtager snart en mail</div>)}
            {failed && (<div className={styles.failed}><ErrorIcon fontSize="large" />Ups, noget gik galt. Kontakt mig evt. direkte</div>)}
            {missingModules && (<div className={styles.failed}><ErrorIcon fontSize="large" />Vælg en eller flere moduler</div>)}
          </div>
          </form>
        </>)}
      </SectionLayout>
    </Layout >)
}

export default ModerskabIBalanceBuy
